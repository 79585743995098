import React, { Component } from "react";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";
import { REQUEST_INTERVIEW } from "../../reducers/candidate";
import HArcGraph from "../../components/harcgraph";
import HToggle from "../../components/htoggle";
import leadership from "../../images/competencies/leadership.svg";
import archivementOrientation from "../../images/competencies/archivementOrientation.svg";
import strategic from "../../images/competencies/strategic.svg";
import starDetail from "../../images/starDetail.svg";
import teamwork from "../../images/competencies/teamwork.svg";
import developingOthers from "../../images/competencies/developingOthers.svg";
import functionProficiency from "../../images/competencies/functionProficiency.svg";
import commercialDrive from "../../images/competencies/commercialDrive.svg";
import changeManagement from "../../images/competencies/changeManagement.svg";
import leadershipPurple from "../../images/competencies/leadershipPurple.svg";
import achievementOrientation2 from "../../images/competencies/achievementOrientation2.svg";
import strategicBoard from "../../images/competencies/strategicBoard.svg";
import teamBlue from "../../images/competencies/teamBlue.svg";
import shapeDiagonal from "../../images/competencies/shapeDiagonal.svg";
import magazineNews from "../../images/competencies/magazineNews.jpg";
import conferenceNews from "../../images/competencies/conferenceNews.jpg";
import paperNews from "../../images/competencies/paperNews.jpeg";
import candidate4 from "../../images/competencies/candidate4.jpg";
import calibrate from "../../images/calibrate.svg";
import FeedbackModal from "../feedback";
import { withTranslation } from "react-i18next";
import HCalibrateSolid from "../../components/hcalibrateSolid";
import "./index.css";
import Handbook from "../../utils/handbook";
import ReactTooltip from "react-tooltip";

const iconsCompetencies = {
  TL: leadership,
  AO: archivementOrientation,
  ST: strategic,
  TC: teamwork,
  DO: developingOthers,
  EI: functionProficiency,
  CO: commercialDrive,
  CM: changeManagement,
};

const iconsComparative = {
  TL: leadershipPurple,
  AO: achievementOrientation2,
  ST: strategicBoard,
  TC: teamBlue,
  DO: leadershipPurple,
  EI: achievementOrientation2,
  CO: strategicBoard,
  CM: teamBlue,
};

const cardNews = {
  card1: magazineNews,
  card2: magazineNews,
  card3: magazineNews,
};

const pictureCandidate = {
  C1: candidate4,
  C2: candidate4,
  C3: candidate4,
};
const mapFlags = {
  silence: "fa-comment-o",
  wrong_language: "fa-language",
  copy_paste: "fa-clipboard",
  quick: "fa-clock-o",
};

class DetailCandidate extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.showFeedback = this.showFeedback.bind(this);
    this.print = this.print.bind(this);
    this.getPersonalityText = this.getPersonalityText.bind(this);
    this.state = {
      detailCandidate: {},
      showFeedback: false,
      feedbackIndex: 0,
    };
    this.competencies = {
      TL: "Leadership",
      AO: "Results Orientation",
      ST: "Strategic Thinking",
      TC: "Team Work & Collaboration",
      DO: "Developing Others",
      EI: "Emotional Intelligence",
      CO: "Commercial Drive",
      CM: "Change Management",
    };
    this.createTexts(t);
  }

  createTexts(t) {
    const handbook = Handbook.createTexts(t);
    this.skillsHeaders = handbook.skillsHeaders;
    this.skillsTooltip = handbook.skillsTooltip;
  }

  componentDidMount() {
    if (this.props.interviewId) {
      this.props.candidateActions.REQUEST_INTERVIEW({
        interview: this.props.interviewId,
      });
    }
    if (window.location.href.indexOf("beta") >= 0) {
      this.beta = true;
    }
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.interviewId &&
      this.props.interviewId !== nextProps.interviewId
    ) {
      this.props.candidateActions.REQUEST_INTERVIEW({
        interview: nextProps.interviewId,
      });
    }
    this.setState({
      psychoSelected: "global",
      psycho: nextProps.selectedInterview.getIn([
        "assessment",
        "psychometric",
        "global",
      ]),
    });
    this.createTexts(nextProps.t);
  }

  selectPsycho(psycho, key) {
    console.log(psycho);
    this.setState({
      psychoSelected: key,
      psycho: psycho,
    });
  }

  render() {
    const { selectedInterview, candidates, feedback, candidate, t } =
      this.props;
    const { showFeedback, feedbackIndex, psycho } = this.state;
    const pychoFacet = this.getPsychoFeature("facet");
    const pychoNeeds = this.getPsychoFeature("needs");
    const pychoValues = this.getPsychoFeature("values");
    const pychoBig = psycho ? psycho.get("big5") : null;
    let feedbackKeys = [];
    if (feedback) {
      feedbackKeys = Object.keys(feedback);
    }

    return selectedInterview.getIn(["assessment"]) ? (
      <div className="detail">
        <ReactTooltip
          id="new"
          place="right"
          multiline={true}
          isCapture={false}
          getContent={(s) => {
            if (!s) {
              return null;
            }
            try {
              const data = JSON.parse(s);
              return (
                <div>
                  <div className="tooltipTitle">Level {data.index}</div>
                  {data.elements.map((e) => (
                    <div>- {e}</div>
                  ))}
                </div>
              );
            } catch (e) {
              console.log(s);
              return null;
            }
          }}
        />
        <ReactTooltip
          id="pill"
          place="bottom"
          multiline={false}
          isCapture={false}
        />
        <div className="detail-title">{t("Competency Report")}</div>

        <div
          key={this.props.i18n.language + " detail-competencies"}
          className="detail-competencies overflowDetail"
        >
          {selectedInterview
            .getIn(["assessment", "competencies"])
            .map((e, k) => this.renderCompetency(e, k))
            .toArray()}
        </div>
        {this.beta && selectedInterview.getIn(["flags"]) ? (
          <div className="flags">
            {selectedInterview
              .getIn(["flags"])
              .map((e, k) => this.renderFlag(e, k))
              .toArray()}
          </div>
        ) : null}
        <div className="contendeor">
          <div className="row detailC">
            {selectedInterview
              .getIn(["assessment", "competencies"])
              .map((c, k) => this.renderComparativeSolid(c, k))
              .toArray()}
            {/*detailCandidate.assessment.comparative.map((e)=> this.renderComparative(e))*/}
          </div>
        </div>
     
        <div className="psycReport">
          <div className="psychometricReport">
            <div className="detail-title margin-top-20">
              {t("Psychometric Report")}
            </div>
        
            {/*
            <div className="margin-top-20" style={{ margin: 20 }}>
              {this.getPersonalityText()}
            </div>
            */}
          </div>
          {this.state.psycho ? (
            <div className="psychoContainer">
              <div className="psychoColumn">
                {pychoFacet.map((e, i) => this.renderPsycho(e, i))}
              </div>
              <div className="psychoColumn">
                {pychoNeeds.map((e, i) => this.renderPsycho(e, i))}
              </div>
              <div className="psychoColumn">
                {pychoValues.map((e, i) => this.renderPsycho(e, i))}
              </div>
            </div>
          ) : null}
          {this.state.psycho && pychoBig ? (
            <div className="psychoContainer">
              <div className="psychoColumnFull">
                {pychoBig.map((e,i)=> this.renderPsycho(e,i)) }
              </div>							
            </div>
          ) : null}
          <div className="buttonPsyFeed">
            <button
              type="button"
              onClick={this.showFeedback}
              className="btn btn-outline-primary psyFeedback"
            >
              {t("Competencies feedback report")}
            </button>
          </div>
          <div className="no-print">
            <FeedbackModal
              feedback={feedback}
              candidate={candidate}
              index={feedbackIndex}
              assessment={selectedInterview
                .getIn(["assessment", "competencies"])
                .map((e, k) => this.renderFeedback(e, k))
                .valueSeq()
                .toArray()}
              show={showFeedback}
              onClose={() => this.setState({ showFeedback: false })}
              previous={() =>
                this.setState({
                  feedbackIndex:
                    feedbackIndex - 1 < 0
                      ? selectedInterview
                          .getIn(["assessment", "competencies"])
                          .count() - 1
                      : feedbackIndex - 1,
                })
              }
              next={() =>
                this.setState({
                  feedbackIndex:
                    feedbackIndex + 1 ===
                    selectedInterview
                      .getIn(["assessment", "competencies"])
                      .count()
                      ? 0
                      : feedbackIndex + 1,
                })
              }
            />
          </div>
          <div
            className="onlyPrint page-break-inside-avoid"
            style={{ flexDirection: "column" }}
          >
            {feedbackKeys.map((e, i) => (
              <FeedbackModal
                feedback={feedback}
                candidate={candidate}
                index={i}
                assessment={selectedInterview
                  .getIn(["assessment", "competencies"])
                  .map((e, k) => this.renderFeedback(e, k))
                  .valueSeq()
                  .toArray()}
                show={true}
                onClose={() => this.setState({ showFeedback: false })}
                previous={() => {}}
                next={() => {}}
              />
            ))}
          </div>
          {showFeedback ? (
            <div className="modal-backdrop fade show"></div>
          ) : null}
        </div>
        {this.beta && selectedInterview.getIn(["sentiment_graph"]) ? (
        <div className="psycReport margin-top-20 margin-bottom-20">
          <div className="detail-title margin-top-20">
            {t("Sentiment Report")}
          </div>
          
              <div className="sentiment_graph">
                <img src={selectedInterview.getIn(["sentiment_graph"])} />
              </div>
            
        </div>
        ) : null}
        <div className="candidatePic page-break-inside-avoid">
          <div className="detail-title margin-top-20 margin-bottom-20">
            {t("Candidate pictures during the test")}
          </div>
          <div className="reduceRow">
            <div className="row">
              {selectedInterview
                .getIn(["images"], [])
                .map((c) => this.renderCandidatePic(c))}
              {/*detailCandidate.assessment.images.map((e)=> this.renderCandidatePic(e))*/}
            </div>
          </div>
        </div>
        <div className="row">
          <a
            href="#"
            onClick={this.print}
            target="_blank"
            type="button"
            className="btn btn-primary reviewButton"
          >
            {t("Print report")}
          </a>
        </div>
      </div>
    ) : (
      <div className="detail">
        <div className="detail-title">{t("Competency Report")}</div>
        <div className="detail-tooltip">
          <div>{t("This candidate doesn't have any assessment")}.</div>
        </div>
      </div>
    );
  }

  getPersonalityText() {
    const personalityEn = this.props.selectedInterview.getIn([
      "assessment",
      "personality_text",
    ]);
    if (this.props.i18n.language.indexOf("es") >= 0) {
      return this.props.selectedInterview.getIn(
        ["assessment", "personality_text_es"],
        personalityEn
      );
    }
    return personalityEn;
  }

  getPsychoFeature(feature) {
    return this.state.psycho && this.state.psycho.getIn([feature, "positive"])
      ? this.state.psycho.getIn([feature, "positive"])
      : [];
  }

  renderFlag(c, k) {
    const { t } = this.props;
    let tooltip = "";
    if (c == "quick") {
      tooltip = t(`${k}_tooltip`);
    } else {
      tooltip =
        t(`${k}_tooltip`) +
        c +
        (c > 1 ? ` ${t("answers")}` : ` ${t("answer")}`);
    }
    if (k == "person_on_camera" && c > 100) {
      return null;
    } else if (k == "person_on_camera") {
      <div className="pill" data-for="pill" data-tip={tooltip}>
        <i className={`fa ${mapFlags[k]}`}></i>
        &nbsp; {t(k)}
      </div>;
    }
    return (
      <div className="pill" data-for="pill" data-tip={tooltip}>
        <i className={`fa ${mapFlags[k]}`}></i>
        &nbsp; {t(k)} {c > 1 ? ` x ${c}` : ""}
      </div>
    );
  }

  renderCompetency(c, k) {
    const psycho = this.props.selectedInterview.getIn([
      "assessment",
      "psychometric",
      k,
    ]);

    const positive = psycho ? psycho.get("approves") : 0;

    return (
      <div key={k} className="detail-competency print-circles">
        <CircularProgressbar className="primary" value={positive * 100} />
        <div className="detail-icon">
          <img src={`${iconsCompetencies[k]}`} />
        </div>
        <div
          key={this.props.i18n.language + "name" + k}
          className={"detail-name " + this.props.i18n.language}
        >
          {`${this.props.t(this.competencies[k])}`}
        </div>
        <div className="detail-evaluation">
          {`${c.get("score")}`} <span className="separator">/</span>{" "}
          <span className="target">{`${c.get("target")}`}</span>
        </div>
        <div className="detail-score"></div>
      </div>
    );
  }

  renderFeedback(c, k) {
    return {
      key: k,
      name: this.competencies[k],
      score: c.get("score"),
      target: c.get("target"),
      percentile: c.get("percentile"),
    };
  }

  renderTags(c) {
    return (
      <a
        className="btn tagBtn"
        href="}
	  margin-left:10px; "
        role="button"
      >{`${c.get("name")}`}</a>
    );
  }

  renderPsycoRepo(c) {
    return (
      <div className="rowPsycoReport">
        <label className="nameReport">{`${c.get("name")}`}</label>
        <label className="porcentageReport">
          {`${Math.floor(c.get("raw_score") * 10000) / 100}`}%
        </label>
      </div>
    );
  }

  renderArcGraph(e) {
    return (
      <div>
        <HArcGraph className={e.clase} />
      </div>
    );
  }

  renderGraphMentions(c) {
    return (
      <div className="boxGraphMentions">
        <CircularProgressbar
          textForPercentage={(n) => n}
          percentage={`${c.get("percent")}`}
          strokeWidth={`${c.get("stroke")}`}
        />
        <p className="txtNewMentions">{this.props.t("New mentions")}</p>
      </div>
    );
  }

  renderMentions(c) {
    return (
      <div>
        <label>{`${c.get("name")}`}</label>
        <img className="diagonal" src={shapeDiagonal} />
      </div>
    );
  }

  renderNews(c) {
    return (
      <div className="card spaceCard" style={{ width: "204px" }}>
        <img
          className="card-img-top"
          src={`${cardNews[c.get("id")]}`}
          alt="Card image cap"
        />
        <div className="card-body newsCard">
          <div className="cardText">{`${c.get("name")}`}</div>
          <div className="cardTextMin">{`${c.get("nota")}`}</div>
        </div>
      </div>
    );
  }

  renderCandidatePic(c) {
    return (
      <div className="col">
        <div className="text-center">
          <img
            style={{ width: "100%" }}
            src={`${c}`}
            className="rounded"
            alt="candidato"
          />
        </div>
      </div>
    );
  }

  renderSentiment(c) {
    return (
      <div className="col-sm-4 col-xs-12">
        <div className="circleRow">
          <CircularProgressbar
            className={`${c.get("color")}`}
            percentage={`${c.get("percent")}`}
            strokeWidth={8}
          />
          <p className="chartText">{`${c.get("name")}`}</p>
        </div>
      </div>
    );
  }

  renderComparativeSolid(v, i) {
    return (
      <HCalibrateSolid
        tooltips={this.skillsTooltip[i]}
        key={i}
        step={v.get("score")}
        steps={5}
        payload={i}
        onChange={this._onCalibrate}
        description={this.props.t(this.competencies[i])}
        progress={v.get("score") === 0 ? -1 : ((v.get("score") - 1) * 100) / 5}
        target={v.get("target")}
        initial="Less"
        final="More"
      />
    );
  }

  renderComparative(c, k) {
    return (
      <div className="candidatos teamBlues">
        <div className="flat">
          <img
            className="imgCandidate imgCandidatos"
            src={`${iconsComparative[k]}`}
          />
        </div>
        <div className="parcial">
          <div className="info">
            <div className="skillsCandidate">{`${this.props.t(
              this.competencies[k]
            )}`}</div>

            <div className="percentagem-num">{`${c.get("score")} ${this.props.t(
              "of"
            )} ${c.get("target")}`}</div>
          </div>
          <div className="progressBarSki">
            <div
              className="percentagem"
              style={{ width: (c.get("score") / 6) * 100 + "%" }}
              title="Candidate Score"
            ></div>
            <div
              className="percentageIndicator"
              style={{
                width: "36",
                position: "relative",
                top: -15,
                left: "calc(" + (c.get("target") / 6) * 100 + "%)",
              }}
            >
              <img src={calibrate} alt="Target Score" title="Target score" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPsycho(p, i) {
    return (
      <div key={i} className="candidatos teamBlues withoutImg">
        <div className="parcial">
          <div className="info">
            <div className="skillsCandidate">{`${this.props.t(
              p.get("name")
            )}`}</div>
            <div className="percentagem-num">
              {`${Math.round(p.get("raw_score") * 10000) / 100}`}%
            </div>
          </div>
          <div className="progressBarSki">
            <div
              className="percentagem"
              style={{ width: p.get("raw_score") * 100 + "%" }}
            ></div>
          </div>
        </div>
      </div>
    );
  }

  showFeedback() {
    this.setState({
      showFeedback: true,
    });
  }
  print(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    window.print();
  }
}

function mapStateToProps(state) {
  return {
    interview: state.candidate.get("interview"),
    selectedInterview: state.candidate.get("selectedInterview"),
    feedback: state.vacancy.get("feedback"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ push }, dispatch),
    candidateActions: bindActionCreators({ REQUEST_INTERVIEW }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DetailCandidate));
