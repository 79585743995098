import React, { Component } from "react";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Drawer from "react-motion-drawer";

import Vacancies from "../vacancies";
import WizardVacancy from "../wizardVacancy";
import GraphCandidates from "../graphCandidates";
import Candidates from "../candidates";
import Feedback from "../feedback";
import PreRecording from "../preRecording";
import Payment from "../payment";

import vacanciesIcon from "../../images/vacanciesIcon.svg";
import hermanWhite from "../../images/hermanWhite.svg";

import "./index.css";

class Home extends Component {
  constructor(props) {
    super(props);
    this._openDrawer = this._openDrawer.bind(this);
    this._addVacancy = this._addVacancy.bind(this);
    this._closeVacancy = this._closeVacancy.bind(this);
    this.renderNav = this.renderNav.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this._goToHome = this._goToHome.bind(this);
    this._showLanguage = this._showLanguage.bind(this);
    this._signOut = this._signOut.bind(this);
    this.state = {
      open: false,
      screen: props.location.pathname,
      preRecording: props.location.pathname == "/preRecording",
      payment: props.location.pathname == "/payment",
      showLanguageMenu: false,
    };
  }
  componentWillReceiveProps(newProps) {
    this.setState({
      preRecording: newProps.location.pathname == "/preRecording",
      payment: newProps.location.pathname == "/payment",
      screen: newProps.location.pathname,
    });
  }

  render() {
    const { t } = this.props;
    const { open, adding, preRecording, screen, payment, showLanguageMenu } =
      this.state;
    return (
      <div className="home background-modal">
        <nav className="navbar navbar-expand-lg navbar-light bg-dark bg-blue space-bar">
          <form className="form-inline">
            <button
              onClick={this._openDrawer}
              className="btn btn-dark"
              type="button"
            >
              <i className="fa fa-bars" aria-hidden="true"></i>
            </button>
          </form>
          <img src={hermanWhite} />
          <div className="dummy-title-button" />
        </nav>
        {this.renderNav()}
        <Drawer
          open={open}
          width={300}
          onChange={(open) => this.setState({ open: open })}
        >
          {(val) => (
            <div className="drawer">
              <div className="title"></div>
              <ul className="list">
                <li onClick={this._goToHome}>
                  <img src={vacanciesIcon} />
                  <span>{t("My Vacancies")}</span>
                </li>
                <li onClick={this._showLanguage}>
                  <img src={vacanciesIcon} />
                  <span>{t("Change language")}</span>
                </li>
                <ul className={"sub-li " + (showLanguageMenu ? "show" : "")}>
                  <li onClick={this._selectLanguage.bind(this, "es")}>
                    Español
                  </li>
                  <li onClick={this._selectLanguage.bind(this, "en")}>
                    English
                  </li>
                </ul>
                <li onClick={this._signOut}>
                  <img src={vacanciesIcon} />
                  <span>{t("Sign out")}</span>
                </li>
              </ul>
            </div>
          )}
        </Drawer>
        {this.renderContent()}
        <PreRecording onClose={this._closeVacancy} visible={preRecording} />
        <Payment onClose={this._closeVacancy} visible={payment} />
      </div>
    );
  }
  _signOut() {
    localStorage.removeItem("accessToken");
    this.props.actions.push("/login");
  }
  _showLanguage() {
    this.setState({
      showLanguageMenu: true,
    });
  }
  _selectLanguage(language) {
    this.setState(
      {
        showLanguageMenu: false,
        open: false,
      },
      () => {
        localStorage.setItem("language", language);
        this.props.i18n.changeLanguage(language);
      }
    );
  }
  renderNav() {
    const { t } = this.props;
    const { screen } = this.state;

    switch (screen) {
      case "/new":
        return (
          <nav className="navbar navbar-expand-lg navbar-light bg-dark bg-gray center-bar">
            <div className="blue-text">
              {t("Publish and Start the Interviews")}
            </div>
          </nav>
        );
      case "/payment":
      case "/preRecording":
      case "/home":
        return (
          <nav className="navbar navbar-expand-lg navbar-light bg-dark bg-gray center-bar">
            <div className="blue-text">{t("Vacancy Management")}</div>
          </nav>
        );
      case "/plan":
        return (
          <nav className="navbar navbar-expand-lg navbar-light bg-dark bg-gray center-bar">
            <div className="blue-text">{t("Choose your plan")}</div>
          </nav>
        );
      case "/candidates":
        return (
          <nav className="navbar navbar-expand-lg navbar-light bg-dark bg-gray center-bar">
            <div className="blue-text">{t("Candidate progress")}</div>
          </nav>
        );
      case "/email":
        return (
          <nav className="navbar navbar-expand-lg navbar-light bg-dark bg-gray center-bar">
            <div className="blue-text">
              {t("Publish and Start the Interviews")}
            </div>
          </nav>
        );
      case "/invite":
        return (
          <nav className="navbar navbar-expand-lg navbar-light bg-dark bg-gray center-bar">
            <div className="blue-text">
              {t("Publish and Start the Interviews")}
            </div>
          </nav>
        );
      case "/record":
        return (
          <nav className="navbar navbar-expand-lg navbar-light bg-dark bg-gray center-bar">
            <div className="blue-text">{t("Welcome video")}</div>
          </nav>
        );

      default:
        return null;
    }
  }
  renderContent() {
    let { screen } = this.state;
    let routeParts = screen.split("/");

    switch (routeParts[1]) {
      case "record":
      case "new":
      case "email":
      case "invite":
      case "payment":
      case "preRecording":
      case "plan":
        return (
          <WizardVacancy
            screen={routeParts[1]}
            uuid={routeParts.length == 3 ? routeParts[2] : null}
          />
        );
      case "home":
        return <Vacancies addVacancy={this._addVacancy} />;
      case "graph":
        return (
          <GraphCandidates
            detail={routeParts.length >= 5 && routeParts[3] == "detail"}
            location={this.props.location}
            match={this.props.match}
          />
        );
      case "candidates":
        return (
          <Candidates location={this.props.location} match={this.props.match} />
        );
      case "feedback":
        return <Feedback />;
    }
  }

  _addVacancy() {
    this.props.actions.push("/new");
  }

  _closeVacancy(success, needVideo) {
    this.setState({
      adding: false,
    });
    if (needVideo) {
      this.props.actions.push("/record");
    }
  }

  _openDrawer() {
    this.setState({
      open: !this.state.open,
    });
  }

  _goToHome() {
    this.props.actions.push("/home");
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ push }, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Home));
