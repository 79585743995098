import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import closeIcon from "../../images/close.svg";
import loadPayment from "../../images/loadPayment.gif";
import checkPayment from "../../images/checkPayment.svg";
import errorPayment from "../../images/errorPayment.svg";
import afganistanFlag from "../../images/payment/flags/afganistanFlag.svg";
import HButton from "../../components/hbutton";
import HCountry from "../../components/hcountry";
import HLoading from "../../components/hloading";
import HInput from "../../components/hinput";
import { withTranslation } from "react-i18next";

import "./index.css";

const plans = {
  1: {
    tests: 100,
    price: 49,
  },
  2: {
    tests: 25,
    price: 53,
  },
  3: {
    tests: 13,
    price: 61,
  },
  4: {
    tests: 5,
    price: 67,
  },
};

const placeholderNumberCard = "Card number";

const placeholderExpDate = "Exp. Date";

const placeholderCvv = "CVV";

const levels = [1, 2, 3, 4];
const sections = [
  { text: "My resume" },
  { text: "" },
  { text: "" },
  { text: "" },
];

class Payment extends Component {
  constructor(props) {
    super(props);
    this._nextSection = this._nextSection.bind(this);
    this.renderResume = this.renderResume.bind(this);
    this.renderSuccessful = this.renderSuccessful.bind(this);
    this.renderError = this.renderError.bind(this);
    this.renderProcessing = this.renderProcessing.bind(this);
    this._close = this._close.bind(this);
    this.state = {
      visible: props.visible || false,
      section: 0,
      numberCard: placeholderNumberCard,
      expDate: placeholderExpDate,
      codeCvv: placeholderCvv,
    };
    this.processVisibility(this.state.visible);
  }
  componentWillReceiveProps(newProps) {
    this.setState({
      visible: newProps.visible,
    });
  }
  componentWillUpdate(nextProps, nextState) {
    this.processVisibility(nextState.visible);
  }
  render() {
    const { visible, section } = this.state;
    const title = sections[section].text;
    return (
      <div
        className={"modal fade " + (visible ? " show" : "")}
        style={{ display: visible ? "block" : "none" }}
      >
        <div className="modal-dialog modal-sm modalPayment" role="document">
          <div className="modal-content">
            <div style={{ width: 100 }}></div>
            <div>
              <h5 className="titlePayment text-center">{title}</h5>
            </div>

            <div className="modal-body bodyPaymentBox">
              {this.renderSection()}
            </div>
            <div className="modal-footer center margin-bottom-10 footPaymentBox">
              {this.renderButtons()}
            </div>
            <button
              onClick={this._close}
              type="button"
              className="close"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <img src={closeIcon} />
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }
  renderSection() {
    const { section } = this.state;
    switch (section) {
      case 0:
        return this.renderResume();
      case 1:
        return this.renderSuccessful();
      case 2:
        return this.renderError();
      case 3:
        return this.renderProcessing();
      default:
        return null;
    }
  }
  renderButtons() {
    const { section } = this.state;
    const { t } = this.props;
    switch (section) {
      case 0:
        return (
          <HButton
            type="primary"
            className="max-300"
            onClick={this._nextSection}
          >
            {t("Process with secure pay")}
          </HButton>
        );
      case 1:
        return (
          <div style={{ textAlign: "center" }}>
            <HButton
              type="primary"
              className="max-300 continuePayment"
              onClick={this._nextSection}
            >
              {t("Continue")}
            </HButton>
          </div>
        );
      case 2:
        return (
          <div style={{ textAlign: "center" }}>
            <HButton
              type="primary"
              className="max-300 continuePayment"
              onClick={this._nextSection}
            >
              {t("Try Again")}
            </HButton>
            <HButton
              type="third"
              className="max-300"
              onClick={this._nextSection}
            >
              {t("Cancel")}
            </HButton>
          </div>
        );
      case 3:
        return <div style={{ textAlign: "center" }}></div>;
    }
  }
  renderResume() {
    const { numberCard, expDate, codeCvv } = this.state;
    const { selectedPlan, t } = this.props;
    const plan = plans[selectedPlan];
    if (selectedPlan == 0) {
      return null;
    }
    return (
      <div>
        <div className="text-center">
          <div className="row">
            <div className="col-8 textPaymentLeft">
              {t("Premium plan")} <br />
              {plan.tests} {t("Herman´s Test")}
            </div>
            <div className="col-4 textPaymentRight">
              <br />
              {plan.price} x {plan.tests}
            </div>
          </div>
          <div className="row">
            <div className="col-8 textPaymentLeftPlus">{t("Total")}</div>
            <div className="col-4 textPaymentRightPlus">
              {plan.tests * plan.price} usd
            </div>
          </div>
          <hr className="barPayments" />
          <div className="row">
            <div className="col">
              <div className="subtitlePayment">{t("Payment process")}</div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="input-group ">
                <HInput
                  className="form-control textInput"
                  placeholder={numberCard}
                  onChange={this.changeNumberCard}
                />
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="form-group col-6 boxExpedition">
                <HInput
                  className="form-control textInput"
                  placeholder={expDate}
                  onChange={this.changeExpDate}
                />
              </div>
              <div className="form-group col-6 boxCode">
                <HInput
                  className="form-control textInput"
                  placeholder={codeCvv}
                  onChange={this.changeCodeCvv}
                />
              </div>
            </div>
            <div className="row">
              <div className="textCode">
                <span className="codeBold">CVV</span>
                <br />
                {t(
                  "A three-digit code located on the back of your credit card"
                )}
              </div>
            </div>
            <HCountry />
          </div>
        </div>
      </div>
    );
  }
  renderSuccessful() {
    const { t } = this.props;
    return (
      <div>
        <div className="text-center">
          <img src={checkPayment} />
          <div className="">
            <div className="titlePaySuccess">{t("Payment Successful")}</div>
            <div className="textPaySuccess">
              {t("Herman is ready to")} <br />
              {t("start interviewing")}
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderError() {
    const { t } = this.props;

    return (
      <div>
        <div className="text-center">
          <img src={errorPayment} />
          <div className="">
            <div className="titlePayError">{t("Error payment")}</div>
            <div className="textPaySuccess">
              {t("Oops, something happened!")} <br />
              {t("Try again or use another card")}.
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderProcessing() {
    return (
      <div>
        <HLoading />
      </div>
    );
  }
  _nextSection() {
    this.setState({
      section: this.state.section + 1,
    });
  }
  _previousSection() {
    this.setState({
      section: this.state.section - 1,
    });
  }
  _close() {
    this.props.actions.push("/plan");
  }
  processVisibility(visible) {
    if (visible) {
      document.getElementById("backdropPayment").className =
        "modal-backdrop show";
      document.getElementById("main").classList.add("modal-open");
    } else {
      document.getElementById("backdropPayment").className = "";
      document.getElementById("main").classList.remove("modal-open");
    }
  }
  changeNumberCard(ev) {
    this.setState({
      name: ev.target.value,
    });
  }
  changeExpDate(ev) {
    this.setState({
      date: ev.target.value,
    });
  }
  changeCodeCvv(ev) {
    this.setState({
      code: ev.target.value,
    });
  }
}

function mapStateToProps(state) {
  return {
    selectedPlan: state.vacancy.get("selectedPlan"),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ push }, dispatch),
  };
}
function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withTranslation()(Payment));
