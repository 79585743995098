import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import HCheckbox from "../../components/hcheckbox";
import Loading from "../../components/loading";
import calendarBlue from "../../images/candidates/edit.svg";
import starDetail from "../../images/starDetail.svg";
import download from "../../images/down_arrow.svg";
import toggleOn from "../../images/toggleOn.svg";
import toggleOff from "../../images/toggleOff.svg";
import HInput from "../../components/hinput";
import { withTranslation } from "react-i18next";

import {
  REQUEST_INTERVIEWS,
  REQUEST_SHARE_URL,
  REQUEST_ROLE,
} from "../../reducers/candidate";
import { REQUEST_ARCHIVED } from "../../reducers/vacancy";
import { ToastContainer, toast } from "react-toastify";

import "./index.css";

const progress = [
  {
    name: "1",
    status: "Process completed",
  },
  {
    name: "2",
    status: "Interview started",
  },
  {
    name: "3",
    status: "Interview started",
  },
  {
    name: "4",
    status: "Interview started",
  },
  {
    name: "5",
    status: "Process completed",
  },
];

const competencies = [
  {
    type: "richardClayton",
    score: 90,
  },
  {
    type: "randyRusell",
    score: 90,
  },
  {
    type: "ceceliaPalmer",
    score: 90,
  },
  {
    type: "connorWebber",
    score: 80,
  },
  {
    type: "charlieUnderwood",
    score: 75,
  },
];

class Candidates extends Component {
  constructor(props) {
    super(props);
    this.showGraph = this.showGraph.bind(this);
    this._nextSection = this._nextSection.bind(this);
    this.editVacancy = this.editVacancy.bind(this);
    this.copyToClpboard = this.copyToClpboard.bind(this);
    this.goToAdd = this.goToAdd.bind(this);
    this.generateLink = this.generateLink.bind(this);
    this._selectTopCandidates = this._selectTopCandidates.bind(this);
    this._compareCandidates = this._compareCandidates.bind(this);
    this._selectCandidate = this._selectCandidate.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.searchCandidate = this.searchCandidate.bind(this);
    this.toggleVacancy = this.toggleVacancy.bind(this);
    this.downloadCandidates = this.downloadCandidates.bind(this);
    this.state = {
      progress: progress,
      competencies: competencies,
      visible: props.visible || false,
      section: 0,
      selectTop: false,
      compareActive: false,
      hiddenFile: null,
    };
    this.hiddenLink = React.createRef();
  }

  componentDidMount() {
    //if (!localStorage.getItem("accessToken")) {
      //For some reason some times it will redirect here instead of the landing page
      //window.location.href = `${window.API_BASE}/api/assessment/vacancy/${this.props.match.params.uuid}/redirect`;
    //  return;
    //}
    this.props.candidateActions.REQUEST_INTERVIEWS({
      vacancy: this.props.match.params.uuid,
    });
    this.props.candidateActions.REQUEST_ROLE();
  }

  render() {
    return (
      <div className="canContainer">
        {this.renderSections()}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          toastClassName="dark-toast"
          progressClassName="transparent-progress"
          closeOnClick
          pauseOnHover
        />
      </div>
    );
  }
  renderSections() {
    const { section } = this.state;

    switch (section) {
      case 0:
        return this.renderProgress();
      case 1:
        return this.renderSelect();
      default:
        return null;
    }
  }
  downloadCandidates() {
    const { candidates, interview, t, role } = this.props;
    if (interview && (interview.get("company") || interview.get("name"))) {
      fetch(
        `${window.API_BASE}/api/assessment/vacancy/${this.props.match.params.uuid}/export/`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      )
        .then((response) => {
          console.log(response);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((myBlob) => {
          console.log("Downloaded!");
          this.setState(
            {
              hiddenFile: URL.createObjectURL(myBlob),
            },
            () => {
              this.hiddenLink.current.click();
            }
          );
        })
        .catch((error) => {
          toast(t("Error when downloading candidates"));
        });
    }
  }
  renderProgress() {
    const { candidates, interview, t, role } = this.props;

    return (
      <div>
        <div className="rowCont">
          <a
            ref={this.hiddenLink}
            href={this.state.hiddenFile}
            download={`${interview.get("company")} ${interview.get(
              "name"
            )}.csv`}
          />
          <div className="row">
            <div className="col-4">
              {role == "A" ? (
                <div
                  className="contCalendar toggle"
                  onClick={this.toggleVacancy}
                >
                  <img
                    className="calendarBlue "
                    src={
                      interview.get("archived") === false ? toggleOn : toggleOff
                    }
                  />
                </div>
              ) : null}
            </div>
            {interview &&
            (interview.get("company") || interview.get("name")) ? (
              <div className="col-4">
                <div className="titleHeader">
                  {interview.get("company") + " " + interview.get("name")}
                </div>

                <div className="subtitleHeader">
                  {interview.get("days")} {t("days to close process")}
                </div>
                {interview.get("share_url") ? (
                  <div className="subtitleHeader">
                    {t("Click on this share link")}{" "}
                    <a href="#" onClick={this.copyToClpboard}>
                      {`${interview.get("share_url")}`}
                    </a>{" "}
                    {t("to copy it in your clipboard or")}
                    {role == "V" ? (
                      " " + t("ask your admin to add more candidates")
                    ) : (
                      <a href="#" onClick={this.goToAdd}>
                        {t("add some candidates")}
                      </a>
                    )}
                  </div>
                ) : (
                  <div className="subtitleHeader">
                    {t("To generate an invite link please")}{" "}
                    <a href="#" onClick={this.goToAdd}>
                      {t("add some candidates")}
                    </a>{" "}
                    {t("or")}{" "}
                    <a href="#" onClick={this.generateLink}>
                      {t("click here")}{" "}
                    </a>
                    {t("to generate invite link now")}
                  </div>
                )}
              </div>
            ) : (
              <div className="col-4">
                <div className="flex-center">
                  <Loading height={80} width={100} />
                </div>
                <div className="titleHeader">{t("Loading...")}</div>
              </div>
            )}
            <div className="col-4 admin-btns">
              {role == "A" ? (
                <div className="contCalendar edit" onClick={this.editVacancy}>
                  <img className="calendarBlue" src={calendarBlue} />
                </div>
              ) : null}
              {role == "A" ? (
                <div className="contCalendar" onClick={this.downloadCandidates}>
                  <img className="calendarBlue" src={download} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="filter searchContainer">
          <HInput
            className="search"
            value={this.state.search}
            onChange={this.onChangeText}
            stateId="search"
            placeholder={t("Search")}
            name="search"
          />
        </div>

        <div className="canContPrincipal">
          {candidates
            .filter(this.searchCandidate)
            .map((c, idx) => this.renderCandidates(c, idx))}
        </div>
        <div className="row">
          {/*<button type="button" className="btn btn-primary reviewButton" onClick={this._selectTopCandidates}>Select top candidates</button>*/}
          <button
            type="button"
            className="btn btn-primary bg-blue-500 reviewButton"
            onClick={this._nextSection}
          >
            {t("Review score")}
          </button>
          {/*<button type="button" className="btn btn-primary reviewButton" onClick={this._compareCandidates}>Compare candidates</button>*/}
        </div>
      </div>
    );
  }
  searchCandidate(c) {
    if (!this.state.search || this.state.search.length < 3) {
      return true;
    }
    if (
      c
        .getIn(["candidate", "first_name"], "")
        .toLowerCase()
        .indexOf(this.state.search.toLowerCase()) != -1
    ) {
      return true;
    }
    if (
      c
        .getIn(["candidate", "last_name"], "")
        .toLowerCase()
        .indexOf(this.state.search.toLowerCase()) != -1
    ) {
      return true;
    }
    return false;
  }
  onChangeText(text, stateId) {
    const newState = {};
    newState[stateId] = text;
    this.setState(newState);
  }
  copyToClpboard(e) {
    e.stopPropagation();
    e.preventDefault();
    let dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.value = this.props.interview.get("share_url");
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    toast(this.props.t("The share link has been copied to your clipboard"));
  }
  toggleVacancy() {
    if (this.props.interview.get("archived") === null) {
      return toast("Can't toggle this vacancy");
    }
    this.props.candidateActions.REQUEST_ARCHIVED({
      uuid: this.props.match.params.uuid,
      archived: !this.props.interview.get("archived"),
    });
  }
  editVacancy() {
    this.props.actions.push(`/email/${this.props.match.params.uuid}`);
  }
  goToAdd(e) {
    e.stopPropagation();
    e.preventDefault();
    this.props.actions.push(`/invite/${this.props.match.params.uuid}`);
  }
  generateLink(e) {
    e.stopPropagation();
    e.preventDefault();
    this.props.candidateActions.REQUEST_SHARE_URL(this.props.match.params.uuid);
  }
  _selectTopCandidates() {
    this.setState({
      selectTop: true,
    });
  }
  _compareCandidates() {
    this.setState({
      compareActive: true,
    });
  }

  renderSelect() {
    const { candidates, interview, t } = this.props;
    return (
      <div>
        <div className="container">
          <div className="row rowTitle">
            <div className="col">
              <div className="titleHeader">{interview.get("name")}</div>
              <div className="subtitleHeader">
                {t(
                  "We send a a rejection later to candidates that did not make it to next round"
                )}
                .
              </div>
            </div>
          </div>
        </div>
        <div className="canContPrincipal">
          {candidates.map((c) => this.renderScoreDue(c))}
        </div>
        <div className="row">
          <button
            type="button"
            className="btn btn-primary bg-blue-500 reviewButton"
          >
            {t("Review score")}
          </button>
        </div>
      </div>
    );
  }

  renderCandidates(c, idx) {
    const { compareActive, selectTop } = this.state;
    const showSelect = compareActive || selectTop;
    const { t } = this.props;
    const statusText = {
      completed: t("Completed"),
      answering: t("Answering"),
      started: t("Started"),
      reviewing: t("Reviewing"),
      waiting: t("Waiting for review"),
    };
    let avatar = c.getIn(["candidate", "image_url"]);
    if (avatar == "/images/placeholder.svg") {
      avatar = c.getIn(["random_image"]);
      if (!avatar || avatar.indexOf("cloudinary") > 1) {
        avatar = "/images/placeholder.svg";
      }
    }

    return (
      <div key={idx} className="row">
        <div
          className="candidatos"
          onClick={this.naviateCandidate.bind(this, c.getIn(["uuid"]))}
        >
          <div className="flat">
            <img className="imgCandidatos" src={avatar} />
          </div>
          {showSelect ? (
            <div className="blockRev">
              <HCheckbox onChange={this._selectCandidate} />
            </div>
          ) : null}
          <div className="parcial">
            <div className="nameCan">{`${c.getIn([
              "candidate",
              "first_name",
            ])} ${c.getIn(["candidate", "last_name"])}`}</div>
            <div className="info">
              <div className="nome">
                {statusText[c.get("status", "started")]}
              </div>
            </div>
            <div className="progressBar">
              <div
                className="percentagem"
                style={{ width: Math.min(c.get("progress"), 100) + "%" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderScoreDue(c) {
    const { t } = this.props;
    return (
      <div className="row">
        <img className="parStarCircle" src={starDetail} />
        <div className="candidatos">
          <div className="flat">
            <img
              className="imgCandidatos"
              src={c.getIn(["candidate", "image_url"])}
            />
          </div>
          <div
            className="vistaRev btn btn-link hidden-xs"
            onClick={this.showGraph}
          >
            {t("Review")}
          </div>
          <div className="blockRev">
            <HCheckbox />
          </div>
          <div className="parcial parcialStar">
            <div className="nameScore">{`${c.getIn([
              "candidate",
              "first_name",
            ])} ${c.getIn(["candidate", "last_name"])}`}</div>
            <div className="info">
              <div className="nomeScore">{c.get("score")}%</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  naviateCandidate(uuid) {
    this.props.actions.push(
      `/graph/${this.props.match.params.uuid}/detail/${uuid}`
    );
  }
  _selectCandidate() {}
  _nextSection() {
    this.props.actions.push(`/graph/${this.props.match.params.uuid}`);
  }
  selectTheBlue(ev) {
    this.setState({
      active: true,
    });
  }
  showGraph() {
    this.props.actions.push(`/graph/${this.props.match.params.uuid}`);
  }
}

function mapStateToProps(state) {
  return {
    candidates: state.candidate.getIn(["interviews", "interview_set"]),
    interview: state.candidate.get("interviews"),
    role: state.candidate.get("role"),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    candidateActions: bindActionCreators(
      { REQUEST_INTERVIEWS, REQUEST_SHARE_URL, REQUEST_ARCHIVED, REQUEST_ROLE },
      dispatch
    ),
    actions: bindActionCreators({ push }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Candidates));
