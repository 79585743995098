import React, { Component } from "react";
import closeIcon from "../../images/close.svg";
import HButton from "../../components/hbutton";
import logoColor from "../../images/logoColor.svg";
import { withTranslation } from "react-i18next";
import "./index.css";

const levels = [1, 2, 3];
const sections = [
  { text: "Relax, Herman will keep candidates updated" },
  { text: "" },
  { text: "Welcome video" },
];

class PreRecording extends Component {
  constructor(props) {
    super(props);
    this._close = this._close.bind(this);
    this._nextSection = this._nextSection.bind(this);
    this._previousSection = this._previousSection.bind(this);
    this.renderRelax = this.renderRelax.bind(this);
    this.renderPlans = this.renderPlans.bind(this);
    this.renderWelcomeV = this.renderWelcomeV.bind(this);
    this.state = {
      visible: props.visible || false,
      section: 0,
    };
    this.processVisibility(this.state.visible);
  }
  componentWillReceiveProps(newProps) {
    this.setState({
      visible: newProps.visible,
    });
  }
  componentWillUpdate(nextProps, nextState) {
    this.processVisibility(nextState.visible);
  }
  render() {
    const { visible, section } = this.state;
    const title = sections[section].text;
    return (
      <div
        className={"modal fade" + (visible ? "show" : "")}
        style={{ display: visible ? "block" : "none" }}
      >
        <div className="modal-dialog modal-lg modalRecording" role="document">
          <div className="modal-content">
            <div className="modal-header headerPreRecording">
              <div style={{ width: 100 }}></div>
              <div className="title">
                <h5 className="titleModal margin-top-20 ">{title}</h5>
              </div>
            </div>
            <div className="modal-body modalBodyTxt">
              {this.renderSection()}
            </div>
            <div className="modal-footer center margin-bottom-20">
              {this.renderButtons()}
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderSection() {
    const { section } = this.state;
    switch (section) {
      case 0:
        return this.renderRelax();
      case 1:
        return this.renderPlans();
      case 2:
        return this.renderWelcomeV();
      default:
        return null;
    }
  }
  renderButtons() {
    const { section } = this.state;
    const { t } = this.props;
    switch (section) {
      case 0:
        return (
          <HButton
            type="primary"
            className="max-300"
            onClick={this._nextSection}
          >
            Ok
          </HButton>
        );
      case 1:
        return (
          <div style={{ textAlign: "center" }}>
            <HButton
              type="primary"
              className="max-300"
              onClick={this._nextSection}
            >
              {t("Yes, see plans")}
            </HButton>
            <HButton
              type="third"
              className="max-300"
              onClick={this._previousSection}
            >
              {t("Edit candidate")}
            </HButton>
          </div>
        );
      case 2:
        return (
          <div style={{ textAlign: "center" }}>
            <HButton
              type="primary"
              className="max-300"
              onClick={this._nextSection}
            >
              {t("Yes, record video")}
            </HButton>
            <HButton
              type="third"
              className="max-300"
              onClick={this._nextSection}
            >
              {t("Continue whitout video")}
            </HButton>
          </div>
        );
    }
  }
  renderRelax() {
    const { t } = this.props;
    return (
      <div>
        <div className="text-center">
          <div className="txtModal">
            {t(
              "No additional tests will be allowed after the deadline. You can change the deadline later in the Vacancy Management Section. To ensure fairness all candidates will receive an automated e-mail when the deadline changes."
            )}
            {t(
              "Also, candidates will receive  automated (1-3-8) reminder e-mails: 1 week, 3 days and 8 hours before the set deadline."
            )}
          </div>
        </div>
      </div>
    );
  }
  renderPlans() {
    return (
      <div>
        <div className="text-center">
          <img className="logoModal" src={logoColor} />
          <div className="">
            {this.props.t("You have")} 2{" "}
            <strong>{this.props.t("FREE  interviews available")}</strong>
            <br />
            {this.props.t("Do you want to add")}{" "}
            <span className="txtSpanModal">3</span>{" "}
            {this.props.t("more candidates ?")}
          </div>
        </div>
      </div>
    );
  }
  renderWelcomeV() {
    return (
      <div>
        <div className="text-center">
          <div className="">
            {this.props.t(
              "Record a welcome video for the candidate, this will help build confidence and better encourage your responses"
            )}
            .
          </div>
        </div>
      </div>
    );
  }
  _nextSection() {
    this.setState({
      section: this.state.section + 1,
    });
  }
  _previousSection() {
    this.setState({
      section: this.state.section - 1,
    });
  }
  _close() {
    this.props.onClose();
  }
  processVisibility(visible) {
    if (visible) {
      document.getElementById("backdropRecording").className =
        "modal-backdrop show";
      document.getElementById("main").classList.add("modal-open");
    } else {
      document.getElementById("backdropRecording").className = "";
      document.getElementById("main").classList.remove("modal-open");
    }
  }
}
export default withTranslation()(PreRecording);
