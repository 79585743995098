import React, { Component } from "react";
import { combineReducers, applyMiddleware, createStore } from "redux";
import {
  ConnectedRouter,
  routerReducer,
  routerMiddleware,
} from "react-router-redux";
import createHistory from "history/createBrowserHistory";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { routes } from "./routes";
import vacancy from "./reducers/vacancy";
import candidate from "./reducers/candidate";
import "./App.css";
import "react-circular-progressbar/dist/styles.css";

const history = createHistory();
const middleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
  router: routerReducer,
  vacancy,
  candidate,
});

const store = createStore(
  reducers,
  applyMiddleware(middleware, sagaMiddleware)
);

sagaMiddleware.run(rootSaga);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>{routes}</ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
