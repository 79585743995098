import { createStore } from "redux";
import { createAction, createReducer } from "redux-act";
import Inmutable from "immutable";

const LOADING_VACANCIES = createAction("LOADING_VACANCIES");
const RESPONSE_VACANCIES = createAction("RESPONSE_VACANCIES");
const REQUEST_VACANCIES = createAction("REQUEST_VACANCIES");

const RESPONSE_POSITIONS = createAction("RESPONSE_POSITIONS");
const REQUEST_POSITIONS = createAction("REQUEST_POSITIONS");

const RESPONSE_CONFIG = createAction("RESPONSE_CONFIG");
const REQUEST_CONFIG = createAction("REQUEST_CONFIG");

const SELECT_PLAN = createAction("SELECT_PLAN");

const REQUEST_NEW_VACANCY = createAction("REQUEST_NEW_VACANCY");

const REQUEST_UPDATE_VACANCY = createAction("REQUEST_UPDATE_VACANCY");

const RESPONSE_VACANCY = createAction("RESPONSE_VACANCY");
const REQUEST_VACANCY = createAction("REQUEST_VACANCY");

const REQUEST_UPDATE_VIDEO = createAction("REQUEST_UPDATE_VIDEO");
const REQUEST_UPDATE_LOGO = createAction("REQUEST_UPDATE_LOGO");

const REQUEST_NEW_CANDIDATES = createAction("REQUEST_NEW_CANDIDATES");
const PROCESS_CANDIDATES = createAction("PROCESS_CANDIDATES");
const REQUEST_FEEDBACK_TEXT = createAction("REQUEST_FEEDBACK_TEXT");
const RESPONSE_FEEDBACK_TEXT = createAction("RESPONSE_FEEDBACK_TEXT");
const REQUEST_ARCHIVED = createAction("REQUEST_ARCHIVED");

const initialState = Inmutable.fromJS({
  vacancies: [],
  positions: [],
  config: {
    competencies: [],
  },
  selectedPlan: 0,
  vacancy: {},
  feedback: {},
  loading:true
});

export default createReducer(
  {
    [LOADING_VACANCIES]: (state, payload) => state.set("loading", payload),
    [RESPONSE_VACANCIES]: (state, payload) => state.set("vacancies", payload),
    [RESPONSE_VACANCY]: (state, payload) => state.set("vacancy", payload),
    [RESPONSE_POSITIONS]: (state, payload) => state.set("positions", payload),
    [RESPONSE_POSITIONS]: (state, payload) => state.set("positions", payload),
    [RESPONSE_CONFIG]: (state, payload) => state.set("config", payload),
    [SELECT_PLAN]: (state, payload) => state.set("selectedPlan", payload),
    [RESPONSE_FEEDBACK_TEXT]: (state, payload) =>
      state.set("feedback", payload),
  },
  initialState
);

export {
  LOADING_VACANCIES,
  RESPONSE_VACANCIES,
  REQUEST_VACANCIES,
  RESPONSE_POSITIONS,
  REQUEST_POSITIONS,
  RESPONSE_CONFIG,
  REQUEST_CONFIG,
  RESPONSE_VACANCY,
  REQUEST_VACANCY,
  SELECT_PLAN,
  REQUEST_NEW_VACANCY,
  REQUEST_UPDATE_VACANCY,
  REQUEST_UPDATE_VIDEO,
  REQUEST_UPDATE_LOGO,
  REQUEST_NEW_CANDIDATES,
  REQUEST_FEEDBACK_TEXT,
  RESPONSE_FEEDBACK_TEXT,
  PROCESS_CANDIDATES,
  REQUEST_ARCHIVED,
};
