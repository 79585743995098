import React, { Component } from "react";
import cancelFeed from "../../images/feedback/cancelFeed.png";
import "./index.css";

export default class KeyQuestion extends Component {
  constructor(props) {
    super(props);
    this.video = React.createRef();
  }

  render() {
    const { show, video } = this.props;
    return (
      <div
        className=" modal fade show videoModal"
        style={{ display: show ? "block" : "none", width: "100vw" }}
      >
        <div className="modal-dialog">
          <div className="modal-content modalKeyQuestion">
            <img
              className="cancelSimbol cancelVideo"
              src={cancelFeed}
              onClick={() => this.close()}
            />
            <video ref={this.video} src={video} controls={true} />
          </div>
        </div>
      </div>
    );
  }
  play() {
    this.video.current.play();
  }
  close() {
    this.video.current.pause();
    this.props.onClose();
  }
}
//https://res.cloudinary.com/dqgdmzjvg/image/upload//ajfi6eumiga55m05ryo5.mp4
//https://res.cloudinary.com/dqgdmzjvg/video/upload/ajfi6eumiga55m05ryo5.mp4
