import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import querystring from "query-string";
import { v4 } from "uuid";

import HButton from "../../components/hbutton";
import HInput from "../../components/hinput";

import {
  REQUEST_LOGIN,
  REQUEST_LOGIN_LINKEDIN,
  REQUEST_SIGNUP,
  REQUEST_SIGNUP_LINKEDIN,
} from "../../reducers/session.js";

import logoColor from "../../images/logoColor.svg";
import lineGrayWhite from "../../images/lineGrayWhite.svg";

import "./index.css";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { firebaseApp } from '../../utils/firebase.js'
import closeIcon from "../../images/close.svg";
import { confirmAlert } from 'react-confirm-alert'; // Import

const AUTHORIZATION_URL = "https://www.linkedin.com/oauth/v2/authorization";



class Login extends Component {
  constructor(props) {
    super(props);
    this._showRegister = this._showRegister.bind(this);
    this._showSignin = this._showSignin.bind(this);
    this._showSigninLinkedin = this._showSigninLinkedin.bind(this);
    this._showPassowrd = this._showPassowrd.bind(this);
    this.doLogin = this.doLogin.bind(this);
    this.doSignup = this.doSignup.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this._authorized = this._authorized.bind(this);
    this._showInfo = this._showInfo.bind(this);
    this._closeInfo =this._closeInfo.bind(this);
    this._recoverPassword = this._recoverPassword.bind(this);
    this.state = {
      type: "signin",
      username: "",
      password: "",
	    repeatPassword: "",
      showingPassword: false,
      processingRegister: false,
      infoVisible: false
    };
  }
  componentDidMount() {
    const parsed = querystring.parse(window.location.search);
    if (parsed.invalidSession) {
      toast(this.props.t("Your session has expired, please log in again"));
    }
  }
  render() {
    const { t } = this.props;
    const { type, username, password, showingPassword } = this.state;

    return (      
      <div className="loginBackground">
         <div
          className={"modal fade " + (this.state.infoVisible ? " show" : "")}
          style={{ display: this.state.infoVisible ? "block" : "none" }}
        >
          <div className="modal-dialog modal-sm modalPayment" role="document">
            <div className="modal-content">
              <div style={{ width: 100 }}></div>
              <div>
                <h5 className=" text-center" style={{fontWeight:"bold", marginTop:20}}>{t('Herman for candidates')}</h5>
              </div>
              <div className="modal-body">
                {t('To use Herman as a candidate please use the link that the Hiring Manager provided to you and download the app.')}
              </div>              
              <button
                onClick={this._closeInfo}
                type="button"
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={closeIcon} />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid full-height" style={{ display: this.state.infoVisible ? "none" : "block" }}>
          <div className="row justify-content-center full-height-40">
            <div className="col-md-6 window login">
              <div className="row justify-content-center margin-top-20">
                <img src={logoColor} />
              </div>
              <div className="row justify-content-center blue font-weight-bold margin-top-20">
                {t("Hi, I'm Herman")}
              </div>
              <div className="row justify-content-center font-weight-light margin-top-10">
                {t("Make the right hiring decisions")}
              </div>
              {type == "signin" ? (
                <div>                  
                  <div className="row justify-content-center margin-top-20">
                    <div className="col-md-7 col-9">
                      <HInput
                        value={username}
                        onChange={this.onChangeText}
                        stateId="username"
                        placeholder={t("User")}
                        type="email"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center margin-top-20">
                    <div className="col-md-7 col-9">
                      <HInput
                        value={password}
                        onChange={this.onChangeText}
                        stateId="password"
                        placeholder={t("Password")}
                        type={!showingPassword ? "password" : "text"}
                        name="password"
                      />
                    </div>
                  </div>
                  <div
                    className="row justify-content-center margin-top-20 text-center font-weight-light clickable"
                    onClick={this._showPassowrd}
                  >
                    {t("Show Password")}
                  </div>
                  <div className="row justify-content-center margin-top-20 text-center ">
                    <div className="col-md-7 col-9">
                      <HButton type="primary" onClick={this.doLogin}>
                        {t("Log in")}
                      </HButton>
                    </div>
                  </div>
                  <div className="row justify-content-center margin-top-20 text-center font-weight-light font-smaller">
                    <button
                      onClick={this._recoverPassword}
                    >
                      {t("Forgot password")}
                    </button>
                  </div>
                  <div className="row justify-content-center margin-top-20 text-center footer font-small ">
                    <div>
                      <span className="font-weight-light">
                        {t("Are you a candidate?")}
                      </span>                      
                      <button
                        onClick={this._showInfo}
                        className="blue font-weight-bold margin-left-10 cursor-pointer"
                      >
                        {t("View Herman for Candidates")}
                      </button>
                    </div>
                  </div>
                  {/*
                  <div className="row justify-content-center margin-top-20 text-center footer font-small ">
                    <div>
                      <span className="font-weight-light">
                        {t("You don't have an account?")}
                      </span>
                      <button
                        onClick={this._showRegister}
                        className="blue font-weight-bold margin-left-10 cursor-pointer"
                      >
                        {t("Register")}
                      </button>
                    </div>
                  </div>
                  */}
                </div>
              ) : (
                <div>
                  <div className="row justify-content-center font-weight-light margin-top-10">
                    <div className="col-11 col-sm-8">
                      <HButton type="linked" onClick={this._showSigninLinkedin}>
                        <i
                          className="fa fa-linkedin-square linked icon"
                          aria-hidden="true"
                        ></i>
                        <span className="font-weight-light">
                          {t("Sign up with")}
                        </span>{" "}
                        <span className="font-weight-semi-bold">
                          {t("LinkedIn")}
                        </span>
                      </HButton>
                    </div>
                  </div>
                  <div className="row justify-content-center margin-top-20">
                    <div className="col-md-8 col-11">
                      <img
                        className="d-inline-block w-40 h-1"
                        src={lineGrayWhite}
                      />
                      <div className="d-inline-block w-20 text-center font-weight-light">
                        {t("or")}
                      </div>
                      <img
                        className="d-inline-block w-40 h-1"
                        src={lineGrayWhite}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center margin-top-20">
                    <div className="col-md-7 col-9">
                      <HInput
                        placeholder={t("First name")}
                        type="text"
                        name="first_name"
                        onChange={this.onChangeText}
                        stateId="first_name"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center margin-top-20">
                    <div className="col-md-7 col-9">
                      <HInput
                        placeholder={t("Last name")}
                        type="text"
                        name="last_name"
                        onChange={this.onChangeText}
                        stateId="last_name"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center margin-top-20">
                    <div className="col-md-7 col-9">
                      <HInput
                        placeholder={t("E-mail")}
                        type="text"
                        name="email"
                        onChange={this.onChangeText}
                        stateId="email"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center margin-top-20">
                    <div className="col-md-7 col-9">
                      <HInput
                        placeholder={t("Your industry")}
                        type="text"
                        name="industry"
                        onChange={this.onChangeText}
                        stateId="industry"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center margin-top-20">
                    <div className="col-md-7 col-9">
                      <HInput
                        placeholder={t("Your current role")}
                        type="text"
                        name="role"
                        onChange={this.onChangeText}
                        stateId="role"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center margin-top-20">
                    <div className="col-md-7 col-9">
                      <HInput
                        placeholder={t("Company name")}
                        type="text"
                        name="company"
                        onChange={this.onChangeText}
                        stateId="company"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center margin-top-20">
                    <div className="col-md-7 col-9">
                      <HInput
                        placeholder={t("Password")}
                        type={!showingPassword ? "password" : "text"}
                        name="password"
                        onChange={this.onChangeText}
                        stateId="password"
                      />
                    </div>
                  </div>
				  <div className="row justify-content-center margin-top-20">
                    <div className="col-md-7 col-9">
                      <HInput
                        placeholder={t("Repeat your Password")}
                        type={!showingPassword ? "password" : "text"}
                        name="repeatPassword"
                        onChange={this.onChangeText}
                        stateId="repeatPassword"
                      />
                    </div>
                  </div>
                  <div
                    className="row justify-content-center margin-top-20 text-center font-weight-light clickable"
                    onClick={this._showPassowrd}
                  >
                    {t("Show Password")}
                  </div>
                  <div className="row justify-content-center margin-top-20 text-center font-weight-light font-small ">
                    {t(
                      "By clicking Register, I accept the Terms of Service and the Privacy Policy"
                    )}
                    .
                  </div>
                  <div className="row justify-content-center margin-top-20 text-center ">
                    <div className="col-md-7 col-9">
                      <HButton
                        type="primary"
                        onClick={this.doSignup}
                        disabled={this.state.processingRegister}
                        className="disabled:opacity-25"
                      >
                        {!this.state.processingRegister
                          ? t("Register")
                          : `${t("Processing")}...`}
                      </HButton>
                    </div>
                  </div>
                  <div className="row justify-content-center margin-top-20 text-center footer font-small ">
                    <div>
                      <span className="font-weight-light">
                        {t("Do you have an account?")}
                      </span>{" "}
                      <span
                        onClick={this._showSignin}
                        className="blue font-weight-bold margin-left-10 clickable"
                      >
                        {t("Sign in")}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="row justify-content-center margin-top-20 text-center font-weight-light">
                Version {process.env.REACT_APP_VERSION}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          toastClassName="dark-toast"
          progressClassName="transparent-progress"
          closeOnClick
          pauseOnHover
        />
      </div>
    );
  }

  onChangeText(value, stateId) {
    const newState = {};
    newState[stateId] = value;
    this.setState(newState);
  }

  _showRegister() {
    this.setState({
      type: "register",
    });
  }
  _showSignin() {
    this.setState({
      type: "signin",
    });
  }
  _showSigninLinkedin() {
    /*if(IN.User.isAuthorized()){
			return this.requestSigninLinkedin();
		}
		IN.Event.onOnce(IN, "auth", this.requestSigninLinkedin);
		IN.User.authorize(this._authorized);*/
    const url = `${AUTHORIZATION_URL}?${querystring.stringify({
      response_type: "code",
      client_id: "78xy6pjxexae37",
      scope: "r_liteprofile r_emailaddress",
      state: v4(),
      redirect_uri: "https://app.meet-herman.com/verify",
    })}`;
    window.open(url, "_self");
  }
  _authorized() {
    console.log("Authorized");
  }
  /*requestSigninLinkedin() {
		IN.API.Profile("me").fields("email-address").result( (data) =>{
			this.props.actions.REQUEST_LOGIN_LINKEDIN({
				email: data.values[0].emailAddress,
				token: IN.ENV.auth.oauth_token,
				onComplete:(response)=>{
					if(!response.success){
						toast(response.message);
					}
				}
			})
		}).error(()=>{
			toast("LinkedIn fails to authenticate the user.");
		});
	}*/
  /*requestSignupLinkedin() {
		IN.API.Profile("me").fields("email-address").result( (data) =>{
			this.props.actions.REQUEST_SIGNUP_LINKEDIN({
				email: data.values[0].emailAddress,
				token: IN.ENV.auth.oauth_token,
				onComplete:(response)=>{
					if(!response.success){
						IN.User.logout();
						toast(response.message);
					}
				}
			})
		}).error(()=>{
			IN.User.logout();
			toast("LinkedIn fails to authenticate the user.");
		});
	}*/
  _showInfo() { 
    this.setState({
      infoVisible: true
    })
  }
  _closeInfo(){
    this.setState({
      infoVisible:false
    })
  }
  _recoverPassword(){
    confirmAlert({
      title: this.props.t('Recover Password'),
      message: this.props.t('We will send an email to') 
      + " " + this.state.username+ " " 
      + this.props.t("with instructions to recover your account") +"\n"
      + this.props.t("Do you want to proceed?"),
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const auth = getAuth(firebaseApp);
            sendPasswordResetEmail(auth,this.state.username)
            toast(this.props.t("Recovery email sent"))
          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    })
  }
  doLogin() {
    const auth = getAuth(firebaseApp);
    signInWithEmailAndPassword(auth, this.state.username, this.state.password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        return user.getIdToken();                
      })
      .then((idToken)=>{
        this.props.actions.REQUEST_LOGIN({
          idToken,
          onComplete:(response)=>{
            if(!response.success){
              toast(response.message);
            }
          }
        })
      })
      .catch((error) => {        
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode);
        toast(<>
          {this.props.t('User / password invalid')}  <br/>
          {"Error: "+ errorCode}
        </>);
    });
    /*this.props.actions.REQUEST_LOGIN({
      username: this.state.username,
      password: this.state.password,
      onComplete: (response) => {
        if (!response.success) {
          toast(response.message);
        }
      },
    });*/
  }
  _showPassowrd() {
    this.setState({
      showingPassword: !this.state.showingPassword,
    });
  }
  doSignup() {
	if (this.state.password != this.state.repeatPassword) {
		this.onFailedSignup({message:"Passwords doesn't match."})
		return;
	}

    this.setState({ processingRegister: true });

    this.props.actions.REQUEST_SIGNUP({
      data: {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        password: this.state.password,
        email: [this.state.email],
        company: this.state.company,
        role: this.state.role,
        industry: this.state.industry,
      },
      onComplete: (response) => {
        if (!response.success) {
          this.onFailedSignup(response);
        }

        this.setState({ processingRegister: false });
      },
    });
  }
  onFailedSignup(response) {
    toast(this.props.t(response.message));
  }
}

function mapStateToProps(state) {  
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        REQUEST_LOGIN,
        REQUEST_LOGIN_LINKEDIN,
        REQUEST_SIGNUP,
        REQUEST_SIGNUP_LINKEDIN,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Login));
