import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import loadPayment from "../../images/loadPayment.gif";
import "./index.css";

class HLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="boxProcessing">
        {this.renderProcessing(
          loadPayment,
          this.props.t("We are almost there…"),
          this.props.t("Processing payment")
        )}
      </div>
    );
  }

  renderProcessing(imageLoading, textRow1, textRow2) {
    return (
      <div className="text-center">
        <img src={imageLoading} />
        <div className="text-center">
          <div className="txtLoad">
            {textRow1}
            <br />
            {textRow2}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(HLoading);
