import React, { Component } from "react";
import { WithContext as ReactTags, Keys } from "react-tag-input";
import { withTranslation } from "react-i18next";
import closeIcon from "../../images/close.svg";
import "./index.css";

class HInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "Please set visible deadline for candidates",
      email: props.email,
      name: props.name,
      lastName: props.lastName,
      deleteCandidate: props.deleteCandidate,
      tags: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.changeDelete = this.changeDelete.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeLast = this.changeLast.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      name: nextProps.name,
      lastName: nextProps.lastName,
    });
  }

  handleDelete(i) {
    let tags = this.state.tags;
    tags.splice(i, 1);
    this.setState({ tags: tags });
    this.props.changeCandidate(
      this.props.number,
      "email",
      tags.map((e) => e.text)
    );
  }

  handleAddition(tag) {
    let tags = this.state.tags;
    tags.push({
      id: tags.length + 1,
      text: tag,
    });
    this.setState({ tags: tags });
    this.props.changeCandidate(
      this.props.number,
      "email",
      tags.map((e) => e.text)
    );
  }

  handleDrag(tag, currPos, newPos) {
    let tags = this.state.tags;
    tags.splice(currPos, 1);
    tags.splice(newPos, 0, tag);
    this.setState({ tags: tags });
    this.props.changeCandidate(
      this.props.number,
      "email",
      tags.map((e) => e.text)
    );
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    alert("An essay was submitted: " + this.state.value);
    event.preventDefault();
  }

  render() {
    const { email, name, lastName, tags, suggestions } = this.state;
    const { number, t } = this.props;
    return (
      <div>
        <img
          className="cancelCandidate"
          src={closeIcon}
          onClick={this.changeDelete}
        />
        <p className="txtCandidate">
          {t("Candidate")} {number + 1}
        </p>
        <div className="input-group editGroup">
          <input
            className="form-control InfoActive"
            placeholder={t("Name")}
            value={name}
            onChange={this.changeName}
          />
        </div>
        <div className="input-group editGroup">
          <input
            className="form-control InfoActive"
            placeholder={t("Last Name")}
            value={lastName}
            onChange={this.changeLast}
          />
        </div>
        <div className="input-group editGroup">
          <div className="form-controlEs InfoActive">
            <ReactTags
              className="ReactTags__tags ReactTags__selected ReactTags__tagInput ReactTags__tagInputField ReactTags__tag"
              tags={tags}
              handleDelete={this.handleDelete}
              allowAdditionFromPaste={true}
              handleAddition={this.handleAddition}
              handleDrag={this.handleDrag}
              handleInputBlur={this.handleBlur}
              delimiters={[Keys.ENTER, Keys.TAB, 32, 188]}
              placeholder={t("E-mail")}
            />
          </div>
        </div>
        <hr className="infoLine" />
      </div>
    );
  }
  handleBlur(ev) {
    if (ev && ev.indexOf("@") != -1) {
      let tags = this.state.tags;
      tags.push({
        id: tags.length,
        text: ev,
      });
      this.setState(tags);
      this.props.changeCandidate(
        this.props.number,
        "email",
        tags.map((e) => e.text)
      );
    }
  }
  changeName(ev) {
    this.props.changeCandidate(
      this.props.number,
      "first_name",
      ev.target.value
    );
  }
  changeLast(ev) {
    this.props.changeCandidate(this.props.number, "last_name", ev.target.value);
  }
  changeDelete() {
    this.props.deleteCandidate(this.props.deleteCandidate);
  }
}
export default withTranslation()(HInvite);
