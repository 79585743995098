import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import hermanWatermark from "../../images/hermanWatermark.svg";
import plusBlue from "../../images/plusBlue.svg";
import plus from "../../images/plus.svg";
import { REQUEST_VACANCIES } from "../../reducers/vacancy.js";
import { REQUEST_ROLE } from "../../reducers/candidate.js";
import "./index.css";
import HInput from "../../components/hinput";
import toggleOn from "../../images/toggleOn.svg";
import toggleOff from "../../images/toggleOff.svg";
import { withTranslation } from "react-i18next";
// TODO: Remove this animation after migrated to newer react version. and use chacka
import Loading from '../../components/loading';

class Vacancy extends Component {
  constructor(props) {
    super(props);
    this.onChangeText = this.onChangeText.bind(this);
    this.searchVacancy = this.searchVacancy.bind(this);
    this.toggleArchived = this.toggleArchived.bind(this);
    this.state = {
      search: "",
      showArchived: false
    };
  }

  componentDidMount() {
    this.props.vacancyActions.REQUEST_VACANCIES();
    this.props.candidateActions.REQUEST_ROLE();
  }

  render() {
    const vacancies = this.props.vacancies || [];

    const { t, role, vacancyLoading } = this.props;
    return (
      <div
        className={
          "vacancies " + (vacancies.size === 0 ? "vertical-center" : "center")
        }
      >
        { vacancyLoading ? 
          (<div>
          <Loading />
          <p style={{color: "#007cff"}}> Loading your vacancies </p>
          </div>) 
        :
        (<div>
          <div className="filter searchContainer">
            <HInput
              className="search"
              value={this.state.search}
              onChange={this.onChangeText}
              stateId="search"
              placeholder={t("Search")}
              name="search"
            />
            {role == "A" ? (
              <div className="searchContainerArchived">
                <div className="showArchived-text">{t("Show Archived ?")}:</div>
                <img
                  onClick={this.toggleArchived}
                  className="showArchived "
                  src={this.state.showArchived ? toggleOn : toggleOff}
                />
              </div>
            ) : null}
          </div>

          {vacancies.size === 0 ? ( 
            <div>
              <div>
                <img className="watermark" src={hermanWatermark} />
              </div>
              <div className="watermark-text">{t("There are no")}</div>
              <div className="watermark-text">{t("registered vacancies")}</div>
              {role == "A" ? (
                <div className="add-btn" onClick={this.props.addVacancy}>
                  <img src={plusBlue} className="icon-plus" />
                  <span>{t("Add new vacant position")}</span>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="list">
              <div>
                {vacancies
                  .filter(this.searchVacancy)
                  .map((v, i) => this.renderElement(v, i))}
              </div>
              {role === "A" ? (
                <div className="btns-center">
                  <img
                    className="btn-plus"
                    src={plus}
                    onClick={this.props.addVacancy}
                  />
                </div>
              ) : null}
            </div>
          )}
       </div>)}
      </div>
    );
  }
  toggleArchived() {
    this.setState({
      showArchived: !this.state.showArchived,
    });
  }
  searchVacancy(v) {
    let shouldShow = true;
    if (!this.state.showArchived) {
      shouldShow = v.getIn(["archived"]) === false;
    }

    if (!this.state.search || this.state.search.length < 3) {
      return shouldShow;
    }
    if (
      v
        .getIn(["name"], "")
        .toLowerCase()
        .indexOf(this.state.search.toLowerCase()) != -1
    ) {
      return shouldShow;
    }
    if (
      v
        .getIn(["company"], "")
        .toLowerCase()
        .indexOf(this.state.search.toLowerCase()) != -1
    ) {
      return shouldShow;
    }
    return false;
  }
  onChangeText(text, stateId) {
    const newState = {};
    newState[stateId] = text;
    this.setState(newState);
  }
  renderElement(v, i) {
    return (
      <div key={i} className="box" onClick={this.selectVacancy.bind(this, v)}>
        <div className="image">
          <img src={v.get("image_url")} />
        </div>
        <div className="company">{v.getIn(["company"])}</div>
        <div className="position">{v.getIn(["name"])}</div>
        <div className="city">{v.get("city")}</div>
        <div className="remain">
          <span>{this.props.t("Days to finish")}:</span>{" "}
          <span className="days">{v.get("days")}</span>
        </div>
      </div>
    );
  }
  selectVacancy(v) {
    this.props.actions.push(`/candidates/${v.get("uuid")}`);
  }
}
function mapStateToProps(state) {
  console.log("Loading "+ state.vacancy.get("loading"))
  return {
    vacancyLoading: state.vacancy.get("loading"),
    vacancies: state.vacancy.get("vacancies"),
    role: state.candidate.get("role"),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    vacancyActions: bindActionCreators({ REQUEST_VACANCIES }, dispatch),
    candidateActions: bindActionCreators({ REQUEST_ROLE }, dispatch),
    actions: bindActionCreators({ push }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Vacancy));
