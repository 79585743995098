/* global videojs */
import React from "react";
import ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.min.css";
import "./i18n";
import App from "./App";
import "./index.css";
import "./fonts/index.css";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// TODO: Add its own environment variables for production.
window.API_BASE = process.env.REACT_APP_API_BASE;
//window.CLIENT_CREDENTIALS = process.env.REACT_APP_CLIENT_CREDENTIALS;
// window.API_BASE = "https://api.meet-herman.com";
ReactDOM.render(<App />, document.getElementById("root"));
