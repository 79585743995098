import request from "superagent";
import { getAuth } from "firebase/auth";
import {firebaseApp} from "../utils/firebase.js"

const getToken=  async () =>{
  await getAuth(firebaseApp).authStateReady();
  if(getAuth(firebaseApp).currentUser == null){
    const err= new Error("Not authenticated");
    err.status = 403
    throw err;
  }
  return getAuth(firebaseApp).currentUser.getIdToken();
}


export default {
  requestVacancies: async (company) =>{
    const idToken = await getToken()    
    return request
      .get(window.API_BASE + "/api/assessment/vacancy/all/")
      .set("Authorization", "Bearer "+ idToken)
  },
  requestPositions: async () => {
    const idToken = await getToken()
    return request
      .get(window.API_BASE + "/api/assessment/position/all/")
      .set("Authorization", "Bearer "+ idToken)
  },
  requestNewVacancy: async (vacancy) => {
    const idToken = await getToken()
    return request
      .post(window.API_BASE + "/api/assessment/vacancy/")
      .set("Authorization", "Bearer "+ idToken)
      .send(vacancy)
  },
  requestUpdateVacancy: async (vacancy, id) =>{
    const idToken = await getToken()

    return request
      .patch(window.API_BASE + "/api/assessment/vacancy/" + id + "/")
      .set("Authorization", "Bearer "+ idToken)
      .send(vacancy)
  },
  requestConfig: async (config) => {
    const idToken = await getToken()

    return request
      .post(window.API_BASE + "/api/assessment/competency/config/")
      .set("Authorization", "Bearer "+ idToken)
      .send(config)
  },
  requestVacancy: async (id) =>{
    const idToken = await getToken()

    return request
      .get(window.API_BASE + "/api/assessment/vacancy/" + id + "/")
      .set("Authorization", "Bearer "+ idToken)
  },
  requestUpdateVideo: async(id, video) =>{
    const idToken = await getToken()

    return request
      .post(window.API_BASE + "/api/assessment/vacancy/" + id + "/video/")
      .set("Authorization", "Bearer "+ idToken)
      .attach("video", video, "video.webm")
  },
  requestUpdateLogo: async (id, image) => {
    const idToken = await getToken()

    return request
      .post(window.API_BASE + "/api/assessment/vacancy/" + id + "/logo/")
      .set("Authorization", "Bearer "+ idToken)
      .attach("image", image)
  },
  requestNewCandidates: async (id, candidates) => {
    const idToken = await getToken()

    request
      .post(window.API_BASE + "/api/assessment/vacancy/" + id + "/interview/")
      .set("Authorization", "Bearer "+ idToken)
      .send(candidates)
  },
  requestFeedbackText: async () => {
    return request.get("https://herman-prod.firebaseio.com/feedback.json")
  },
  processCandidates: async (uuid, files) => {
    const idToken = await getToken()

    return request
      .post(
        window.API_BASE + "/api/assessment/precandidate/" + uuid + "/batch/"
      )
      .set("Authorization", "Bearer "+ idToken)
      .attach("candidates", files[0])
  }
};
