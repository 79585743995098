import React from "react";
import { Route } from "react-router";
import Login from "../screens/login";
import Start from "../screens/start";
import Home from "../screens/home";
import Handbook from "../screens/handbook";

const routes = (
  <div>
    <Route exact path="/" component={Start} />
    <Route path="/verify" component={Login} />
    <Route path="/login" component={Login} />
    <Route path="/home" component={Home} />
    <Route path="/new" component={Home} />    
    <Route path="/record" component={Home} />
    <Route path="/graph/:uuid" exact component={Home} />
    <Route path="/graph/:uuid/detail/:uuidDetail" exact component={Home} />
    <Route path="/graph/:uuid/detail/:uuidDetail/beta" exact component={Home} />
    <Route path="/email" component={Home} />
    <Route path="/plan" component={Home} />
    <Route path="/invite" component={Home} />
    <Route path="/candidates/:uuid" component={Home} />
    <Route path="/feedback" component={Home} />
    <Route path="/preRecording" component={Home} />
    <Route path="/payment" component={Home} />
    <Route path="/handbook" component={Handbook} />
  </div>
);

export { routes };
