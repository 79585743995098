import { all, spawn, call } from "redux-saga/effects";
import {
  watchRequestVacancies,
  watchRequestPositions,
  watchRequestNewVacancy,
  watchRequestConfig,
  watchRequestVacancy,
  watchRequestUpdateVacancy,
  watchRequestUpdateVideo,
  watchRequestUpdateLogo,
  watchRequestNewCandidates,
  watchRequestFeedbackText,
  watchRequestProcessCandidates,
  watchRequestArchived,
} from "./vacancy.js";
import {
  watchRequestLogin,
  watchRequestSignup,
  watchRequestLoginLinkedin,
  watchRequestSignupLinkedin,
} from "./session.js";
import {
  watchRequestInterviews,
  watchRequestInterview,
  watchRequestShareURL,
  watchRequestRole,
} from "./candidate.js";

export default function* rootSaga() {
  const sagas = [
    watchRequestArchived,
    watchRequestShareURL,
    watchRequestPositions,
    watchRequestVacancies,
    watchRequestLogin,
    watchRequestNewVacancy,
    watchRequestInterviews,
    watchRequestInterview,
    watchRequestConfig,
    watchRequestVacancy,
    watchRequestUpdateVacancy,
    watchRequestUpdateVideo,
    watchRequestUpdateLogo,
    watchRequestNewCandidates,
    watchRequestSignup,
    watchRequestLoginLinkedin,
    watchRequestSignupLinkedin,
    watchRequestFeedbackText,
    watchRequestProcessCandidates,
    watchRequestRole,
  ];
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
