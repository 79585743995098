import { createAction, createReducer } from "redux-act";
import Inmutable from "immutable";
import { create } from "d3-selection";

const RESPONSE_INTERVIEWS = createAction("RESPONSE_INTERVIEWS");
const REQUEST_INTERVIEWS = createAction("REQUEST_INTERVIEWS");
const REQUEST_INTERVIEW = createAction("REQUEST_INTERVIEW");
const RESPONSE_INTERVIEW = createAction("RESPONSE_INTERVIEW");
const REQUEST_SHARE_URL = createAction("REQUEST_SHARE_URL");
const RESPONSE_SHARE_URL = createAction("RESPONSE_SHARE_URL");
const REQUEST_ROLE = createAction("REQUEST_ROLE");
const RESPONSE_ROLE = createAction("RESPONSE_ROLE");

const initialState = Inmutable.fromJS({
  interviews: {
    interview_set: [],
  },
  selectedInterview: {
    assessment: {
      competencies: {},
      flags: {},
      comparative: {},
      psychometric: {},
      newMentions: {},
      mentions: {},
      news: {},
      tags: {},
      sentiments: {},
      images: {},
    },
    images: [],
  },
  role: "V",
});

export default createReducer(
  {
    [RESPONSE_INTERVIEWS]: (state, payload) => state.set("interviews", payload),
    [RESPONSE_INTERVIEW]: (state, payload) =>
      state.set("selectedInterview", payload),
    [RESPONSE_SHARE_URL]: (state, payload) =>
      state.setIn(["interviews", "share_url"], payload),
    [RESPONSE_ROLE]: (state, role) => state.set("role", role),
  },
  initialState
);

export {
  RESPONSE_INTERVIEWS,
  REQUEST_INTERVIEWS,
  RESPONSE_INTERVIEW,
  REQUEST_INTERVIEW,
  REQUEST_SHARE_URL,
  RESPONSE_SHARE_URL,
  REQUEST_ROLE,
  RESPONSE_ROLE,
};
