import { put, takeLatest } from "redux-saga/effects";
import Immutable from "immutable";
import {
  RESPONSE_INTERVIEWS,
  REQUEST_INTERVIEWS,
  REQUEST_SHARE_URL,
  RESPONSE_SHARE_URL,
  RESPONSE_INTERVIEW,
  REQUEST_INTERVIEW,
  RESPONSE_ROLE,
  REQUEST_ROLE,
} from "../reducers/candidate";
import ApiCandidate from "../api/candidate";

export function* requestRole() {
  try {
    const response = yield ApiCandidate.requestProfile();
    if (response.status === 200) {
      yield put(RESPONSE_ROLE(response.body.role_security));
    }
  } catch (e) {}
}

export function* requestInterviews(data) {
  try {
    const response = yield ApiCandidate.requestInterviews(data.payload.vacancy);
    if (response.status === 200) {
      yield put(RESPONSE_INTERVIEWS(Immutable.fromJS(response.body)));
    }
  } catch (e) {
    if (e.status == 403) {
      return window.location.replace("/login?invalidSession=true");
    }
    yield put(
      RESPONSE_INTERVIEWS(
        Immutable.fromJS({
          interview: {
            interview_set: [],
          },
        })
      )
    );
  }
}
const maxRange = 0.7;
const minRange = 0.3;
function getAvg(facets, negativeFacets) {
  let rejects = 0;
  let rejection = 0;
  facets.forEach((f) => {
    if (f.percentile < maxRange) {
      rejection += f.percentile;
      rejects++;
    } else {
      rejection += maxRange;
      rejects++;
    }
  });
  negativeFacets.forEach((f) => {
    if (f.percentile > minRange) {
      rejection += 1 - f.percentile;
      rejects++;
    } else {
      rejection += maxRange;
      rejects++;
    }
  });
  const scale = maxRange;
  return ((rejection / rejects) * 1) / scale;
}

export function* requestInterview(data) {
  try {
    const response = yield ApiCandidate.requestInterview(
      data.payload.interview
    );
    if (response.status === 200) {
      const data = response.body;
      if (data.assessment && data.assessment.psychometric) {
        const keys = Object.keys(data.assessment.psychometric);
        data.assessment.psychometric = keys.reduce((current, key) => {
          const competency = current[key];
          if (
            key != "global" &&
            competency.facet &&
            competency.facet.positive.length > 0 &&
            competency.facet.negative.length > 0
          ) {
            const positive = getAvg(
              competency.facet.positive,
              competency.facet.negative
            );
            competency["approves"] = positive;
          } else if (key != "global") {
            const positive = getAvg(
              competency.needs.positive.concat(competency.values.positive),
              competency.needs.negative.concat(competency.values.negative)
            );
            competency["approves"] = positive;
          }
          return current;
        }, data.assessment.psychometric);
        console.log(data.assessment.psychometric);
      }
      yield put(RESPONSE_INTERVIEW(Immutable.fromJS(data)));
    }
  } catch (e) {
    if (e.status == 403) {
      return window.location.replace("/login?invalidSession=true");
    }
    console.log(e);
    yield put(
      RESPONSE_INTERVIEW(
        Immutable.fromJS({
          assessment: {
            competencies: [],
          },
        })
      )
    );
  }
}

export function* requestShareURL(data) {
  const response = yield ApiCandidate.requestGetShareURL(data.payload);
  console.log(response.body);
  try {
    if (response.body.share_url) {
      yield put(RESPONSE_SHARE_URL(response.body.share_url));
    }
  } catch (e) {}
}

export function* watchRequestShareURL() {
  yield takeLatest(REQUEST_SHARE_URL.getType(), requestShareURL);
}

export function* watchRequestInterviews() {
  yield takeLatest(REQUEST_INTERVIEWS.getType(), requestInterviews);
}

export function* watchRequestInterview() {
  yield takeLatest(REQUEST_INTERVIEW.getType(), requestInterview);
}

export function* watchRequestRole() {
  yield takeLatest(REQUEST_ROLE.getType(), requestRole);
}
