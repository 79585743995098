import { initializeApp } from "firebase/app";

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyBDSu_EV_P4qhXALQxNzhwcUwkQtTONbJY",
  authDomain: "auth.hermanpro.com",
  projectId: "herman-run",
  storageBucket: "herman-run.firebasestorage.app",
  messagingSenderId: "779531163127",
  appId: "1:779531163127:web:d6802e47303a04120a4af2",
  measurementId: "G-BWS3HQ58Q7",
});
