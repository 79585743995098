import { delay } from "redux-saga";
import { put, takeLatest, fork } from "redux-saga/effects";
import Immutable from "immutable";
import { push } from "react-router-redux";
import {
  RESPONSE_LOGIN,
  REQUEST_LOGIN,
  REQUEST_LOGIN_LINKEDIN,
  REQUEST_SIGNUP,
  REQUEST_SIGNUP_LINKEDIN,
} from "../reducers/session";
import ApiSession from "../api/session";

export function* requestLogin(data) {
  //localStorage.setItem("accessToken", data.id_token);
  yield put(RESPONSE_LOGIN(true));
  data.payload.onComplete({ success: true });
  yield put(push("/home"));
  /*const { username, password } = data.payload;
  try {
    const response = yield ApiSession.requestLogin(username, password);
    if (response.body.access_token) {
      localStorage.setItem("accessToken", response.body.access_token);
      yield put(RESPONSE_LOGIN(true));
      data.payload.onComplete({ success: true });
      yield put(push("/home"));
    } else {
      throw new Error("Not access token");
    }
  } catch (e) {
    data.payload.onComplete({
      success: false,
      message: "User / password invalid",
    });
    yield put(RESPONSE_LOGIN(false));
  }*/
}

export function* requestLoginLinkedin(data) {
  const { token } = data.payload;
  try {
    const response = yield ApiSession.requestLoginLinkedin(token);
    if (response.body.access_token) {
      localStorage.setItem("accessToken", response.body.access_token);
      yield put(RESPONSE_LOGIN(true));
      data.payload.onComplete({ success: true });
      yield put(push("/home"));
    } else {
      throw new Error("Not access token");
    }
  } catch (e) {
    console.log(e);
    data.payload.onComplete({
      success: false,
      message: "LinkedIn user doesn't have an account",
    });
    yield put(RESPONSE_LOGIN(false));
  }
}

export function* requestSignup(data) {
  try {
    const response = yield ApiSession.requestSignup(data.payload.data);
    if (response.body.access_token) {
      localStorage.setItem("accessToken", response.body.access_token);
      yield put(RESPONSE_LOGIN(true));
      data.payload.onComplete({ success: true });
      yield put(push("/home"));
    } else {
      if (response.body.error_code === 100) {
        throw new Error("An account with that email address exists already.");
      }

      throw new Error("Can't signup, please try again later");
    }
  } catch (e) {
    console.log(e);
    data.payload.onComplete({ success: false, message: e.message });
    yield put(RESPONSE_LOGIN(false));
  }
}

export function* requestSignupLinkedin(data) {
  const { token } = data.payload;
  try {
    const response = yield ApiSession.requestSignupLinkedin(token);
    if (response.body.access_token) {
      localStorage.setItem("accessToken", response.body.access_token);
      yield put(RESPONSE_LOGIN(true));
      data.payload.onComplete({ success: true });
      yield put(push("/home"));
    } else {
      if (response.body.error_code === 100) {
        throw new Error("An account with that email address exists already.");
      }
      throw new Error("Can't signup, please try again later");
    }
  } catch (e) {
    console.log(e);
    data.payload.onComplete({ success: false, message: e.message });
    yield put(RESPONSE_LOGIN(false));
  }
}

export function* watchRequestLogin() {
  yield takeLatest(REQUEST_LOGIN.getType(), requestLogin);
}

export function* watchRequestLoginLinkedin() {
  yield takeLatest(REQUEST_LOGIN_LINKEDIN.getType(), requestLoginLinkedin);
}

export function* watchRequestSignup() {
  yield takeLatest(REQUEST_SIGNUP.getType(), requestSignup);
}

export function* watchRequestSignupLinkedin() {
  yield takeLatest(REQUEST_SIGNUP_LINKEDIN.getType(), requestSignupLinkedin);
}
