import React, { Component } from "react";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SELECT_PLAN } from "../../reducers/vacancy";
import cancelChoose from "../../images/choosePlan/cancelChoose.svg";
import ovalBlue from "../../images/choosePlan/ovalBlue.svg";
import ovalOrange from "../../images/choosePlan/ovalOrange.svg";
import ovalBlueStrong from "../../images/choosePlan/ovalBlueStrong.svg";
import ovalBlue2 from "../../images/choosePlan/ovalBlue2.svg";
import ovalPistache from "../../images/choosePlan/ovalPistache.svg";
import closeIcon from "../../images/close.svg";
import { withTranslation } from "react-i18next";

import "./index.css";

class ChoosePlan extends Component {
  constructor(props) {
    super(props);
    this.finish = this.finish.bind(this);
  }
  render() {
    const { t } = this.props;
    return (
      <div className="cPlan-container">
        <div className="cPlan">
          <div className="planContainer">
            <div className="row">
              <label className="lineTitle">
                <div className="choose-title">
                  {t("Upgrading makes you feel in control")}}.
                </div>
              </label>
            </div>
            <div className="row">
              <div className="col">
                {this.renderCardUn(
                  "FREE",
                  ovalBlue,
                  t("As a first time user, you first two test are on us!"),
                  t("Free test"),
                  `2 ${t("Herman´s tests")}`,
                  " ",
                  t("Start now!"),
                  t("Try it"),
                  0
                )}
              </div>
              <div className="col">
                {this.renderCard(
                  49,
                  ovalOrange,
                  t("The best value for high volume hiring needs"),
                  t("Premium"),
                  `100 ${t("Herman´s tests")}`,
                  t("per test"),
                  `${t("Total price")} $4900usd`,
                  t("Buy tests"),
                  1
                )}
              </div>
              <div className="col">
                {this.renderCard(
                  53,
                  ovalBlueStrong,
                  `${t("The option for regular hiring needs")}.`,
                  t("Enterprise"),
                  `25 ${t("Herman´s tests")}`,
                  t("per test"),
                  `${t("Total price")} $1325usd`,
                  t("Buy tests"),
                  2
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                {this.renderCard(
                  61,
                  ovalPistache,
                  `${t("The best option for mature volume of hiring")}.`,
                  t("Professional"),
                  `13 {t('Herman´s tests')}`,
                  t("per test"),
                  `${t("Total price")} $793usd`,
                  t("Buy tests"),
                  3
                )}
              </div>
              <div className="col">
                {this.renderCard(
                  67,
                  ovalBlue2,
                  t("The option for a single search with few candidates"),
                  t("Starter"),
                  `5 ${t("Herman´s tests")}`,
                  t("per test"),
                  `${t("Total price")} $335 usd`,
                  t("Buy tests"),
                  4
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderCard(
    price,
    image,
    description,
    title,
    description2,
    pertest,
    totalPrice,
    buttonCard,
    option
  ) {
    return (
      <div className="cardPlan">
        <img className="imgCardHead" src={image} />
        <div className="txtCardHead">{title}</div>
        <div className="txtCardDes">{description}</div>
        <div className="txtCardDes2">{description2}</div>
        <div className="txtCardPrice">${price} usd</div>
        <div className="txtCardPertest">{pertest}</div>
        <div className="txtCardTotal">{totalPrice}</div>
        <button
          type="button"
          className="cardButton"
          onClick={this.showPayment.bind(this, option)}
        >
          {this.props.t("Buy tests")}
        </button>
      </div>
    );
  }

  renderCardUn(
    price2,
    image,
    description,
    title2,
    description2,
    pertest,
    totalPrice,
    buttonCard,
    option
  ) {
    return (
      <div className="cardPlan">
        <img className="imgCardHead" src={image} />
        <div className="txtCardHeadB">{title2}</div>
        <div className="txtCardDes">{description}</div>
        <div className="txtCardDes2">{description2}</div>
        <div className="txtCardPrice2">{price2}</div>
        <div className="txtCardPertest">{pertest}</div>
        <div className="txtCardTotal">{totalPrice}</div>
        <button type="button" className="cardButton2" onClick={this.finish}>
          {this.props.t("Try it")}
        </button>
      </div>
    );
  }
  showPayment(option) {
    this.props.actions.SELECT_PLAN(option);
    this.props.actions.push("/payment");
  }
  finish() {
    this.props.actions.push("/home");
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ push, SELECT_PLAN }, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ChoosePlan));
