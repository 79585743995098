import React, { Component } from "react";
import "./index.css";
import calibrate from "../../images/calibrate.svg";
import calibrateFill from "../../images/calibrate_fill.svg";

export default class HCalibrate extends Component {
  constructor(props) {
    super(props);
    this._move = this._move.bind(this);
  }
  render() {
    const {
      initial,
      final,
      progress,
      description,
      steps,
      tooltips,
      step,
      index,
      target,
    } = this.props;
    let empty = [];
    for (let index = 0; index <= steps * 2; index++) {
      empty.push(true);
    }
    const fill = progress == -1 ? 0 : progress;
    return (
      <div className="calibrateSolid">
        <div className="titleCompetency">{description}</div>
        <div
          ref="bar"
          className="bar"
          onMouseDown={this._move}
          onMouseMove={this._move}
          onTouchMove={this._move}
        >
          <div className="progress" style={{ flex: fill }}>
            {step != 0 ? (
              <div
                className="indicator"
                data-for="new"
                data-tip={JSON.stringify({
                  index: step || 1,
                  elements: tooltips[(step || 1) - 1],
                })}
              >
                <img src={step == target ? calibrateFill : calibrate} />
              </div>
            ) : null}
            {(step * 2) % 2 != 0 && (
              <div className="indicatorNumber">{step}</div>
            )}
          </div>
          <div className="missing" style={{ flex: 100 - fill }}></div>
        </div>

        <div className="labels">
          {empty.map((e, i) => (
            <div key={i} className="axis">
              {i % 2 == 0 && <div className="line"></div>}
              {i % 2 == 0 || target == i / 2 + 1 ? (
                <div
                  onClick={() =>
                    this.props.onChange(i / 2 + 1, this.props.payload)
                  }
                  className={
                    "circle " +
                    (progress < ((100 / steps) * i) / 2
                      ? "inactive "
                      : "active ") +
                    (target == i / 2 + 1 ? "target" : "")
                  }
                  data-for="new"
                  data-tip={JSON.stringify({
                    index: i / 2 + 1,
                    elements: tooltips[i / 2],
                  })}
                ></div>
              ) : null}
              {i % 2 == 0 || target == i / 2 + 1 ? (
                <span
                  className={
                    "number " +
                    (step == i / 2 + 1 ? "active " : "inactive ") +
                    (target == i / 2 + 1 ? "target" : " ")
                  }
                >
                  {i / 2 + 1}
                </span>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  }
  _move(e) {
    if (
      this.props.onChange &&
      (e.buttons > 0 || (e.touches && e.touches.length > 0))
    ) {
      let rect = this.refs.bar.getBoundingClientRect();
      let xClient = e.clientX || e.touches[0].clientX;
      let xPosition = xClient - rect.x;
      let newProgress = (xPosition / rect.width) * 100 + 2;
      if (newProgress > 100) {
        newProgress = 100;
      }
      let newValue = 0;
      if (this.props.steps) {
        let delta = 100 / this.props.steps / 2;
        let n = 0;
        console.log(delta, newProgress);
        while (n < newProgress - delta / 2) {
          n += delta;
          newValue += 0.5;
          console.log(newValue, n);
        }
        newProgress = n;
      }
      if (newProgress > 100) {
        newValue = this.props.steps;
      }
      console.log(newValue);
      this.props.onChange(newValue + 1, this.props.payload);
    }
  }
}
