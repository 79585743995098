import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import { ToastContainer, toast } from "react-toastify";
import { WithContext as ReactTags } from "react-tag-input";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import HInvite from "../../components/hinvite";
import closeIcon from "../../images/close.svg";
import userCandidate from "../../images/invite/userCandidate.svg";
import lineAprobate from "../../images/invite/lineAprobate.svg";
import plus from "../../images/plus.svg";
import "./index.css";
import {
  REQUEST_NEW_CANDIDATES,
  PROCESS_CANDIDATES,
} from "../../reducers/vacancy";
import { withTranslation } from "react-i18next";

const placeholderEmail = "E-mail";

const placeholderName = "Name";

const placheholderLastName = "Last name";

class Invite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.t("Please set visible deadline for candidates"),
      tags: [],
      candidates: [
        {
          first_name: "",
          last_name: "",
          email: "",
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.process = this.process.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.changeCandidate = this.changeCandidate.bind(this);
    this.deleteCandidate = this.deleteCandidate.bind(this);
    this.saveAndRecord = this.saveAndRecord.bind(this);
    this.finalize = this.finalize.bind(this);
    this.candidates = React.createRef();
  }

  handleDelete(i) {
    let tags = this.state.tags;
    tags.splice(i, 1);
    this.setState({ tags: tags });
  }

  handleAddition(tag) {
    let tags = this.state.tags;
    tags.push({
      id: tags.length + 1,
      text: tag,
    });
    this.setState({ tags: tags });
  }

  handleDrag(tag, currPos, newPos) {
    let tags = this.state.tags;

    // mutate array
    tags.splice(currPos, 1);
    tags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: tags });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    const { t } = this.props;
    const { email, name, lastName, tags, suggestions, candidates } = this.state;
    return (
      <div className="inviteContainer">
        <div className="inviteAvailable">
          {t("Candidates available")}:{" "}
          <span className="textAvailable">
            {candidates.length} {t("of")} 5
          </span>
        </div>
        <div className="inContainer">
          <div className="row">
            <div className="inTitlePrincipal">
              {t("Ready to invite candidates?")}
            </div>
          </div>
          <div className="row justify-content-center">
            <p className="inSubTitle">
              {t(
                "You can add more candidates later in the Vacancy Management Section"
              )}
            </p>
          </div>
          <div className="candidateInfo">
            {candidates.map((c, i) => (
              <HInvite
                key={i}
                name={c.first_name}
                lastName={c.last_name}
                number={i}
                changeCandidate={this.changeCandidate}
                deleteCandidate={this.deleteCandidate}
              />
            ))}
          </div>
          <div className="btns-center">
            <img
              className="btn-plus"
              src={plus}
              onClick={() => this.addMore()}
            />
          </div>
          <div className="btns-center">
            {t("Or load candidates from an XLSX file")}
          </div>
          <div className="row justify-content-center">
            <div className="col-md-4 col-xs-12 text-center">
              <div className="btn btn-primary reviewButton emailBatchButton emailButton">
                {t("Choose File")}
                <input
                  className=" emailBatchInput"
                  accept=".xlsx"
                  ref={this.candidates}
                  type="file"
                />
              </div>
            </div>
            <div className="col-md-4 col-xs-12 text-center">
              <button
                style={{ height: 35, padding: 0 }}
                type="button"
                className="btn btn-primary bg-blue-500 reviewButton emailButton"
                onClick={this.process}
              >
                {t("Submit file")}
              </button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4 col-xs-12 text-center">
              <button
                type="button"
                className="btn btn-primary bg-blue-500 reviewButton emailButton"
                onClick={this.finalize}
              >
                {t("Finalize and Invite Candidates")}
              </button>
            </div>
            <div className="col-md-4 col-xs-12 text-center">
              <button
                type="button"
                className="btn btn-primary bg-blue-500 reviewButton emailButton"
                onClick={this.saveAndRecord}
              >
                {t("Save and Record Video")}
              </button>
            </div>
          </div>
        </div>
        {/*<div className="row">
					<button type="button" className="btn btn-link txtUnder">{t('Save for later, do not publish')}</button> 
					</div>*/}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          toastClassName="dark-toast"
          progressClassName="transparent-progress"
          closeOnClick
          pauseOnHover
        />
      </div>
    );
  }
  changeCandidate(id, key, value) {
    const { candidates } = this.state;
    candidates[id][key] = value;
    this.setState({
      candidates: candidates,
    });
  }

  deleteCandidate(i) {
    let candidates = this.state.candidates;

    candidates.splice(i, 1);
    this.setState({ candidates: candidates });
  }

  addMore() {
    let candidates = this.state.candidates;

    if (candidates.length == 5) {
      return;
    }
    candidates.push({
      name: "",
      lastName: "",
    });

    this.setState({ candidates });
  }
  changeName(ev) {
    this.setState({
      name: ev.target.value,
    });
  }
  saveAndRecord() {
    this.createNewCandidates()
      .then(() => {
        this.props.push(`/record/${this.props.vacancy.get("uuid")}`);
      })
      .catch((err) => {
        //TODO: Translate
        toast(err.message);
      });
  }
  finalize() {
    this.createNewCandidates()
      .then(() => {
        this.props.push(`/candidates/${this.props.vacancy.get("uuid")}`);
      })
      .catch((err) => {
        //TODO: Translate
        toast(err.message);
      });
  }
  process() {
    if (this.candidates.current.files.length > 0) {
      this.props.vacancyActions.PROCESS_CANDIDATES({
        uuid: this.props.vacancy.get("uuid"),
        files: this.candidates.current.files,
        onComplete: () => {
          toast(this.props.t("File processed successfully!"));
          this.candidates.current.value = "";
          //this.props.push(`/vacancy/${this.props.vacancy.get("uuid")}`);
        },
      });
    } else {
      toast(this.props.t("Please select a file"));
    }
  }
  createNewCandidates() {
    return new Promise((resolve) => {
      if (this.state.candidates.find((c) => c.email.length === 0)) {
        throw new Error(
          "We can't send an email to the candidates, One candidate doesn't contain a valid email"
        );
      }
      this.props.vacancyActions.REQUEST_NEW_CANDIDATES({
        uuid: this.props.vacancy.get("uuid"),
        candidates: {
          candidates: this.state.candidates,
        },
        onComplete: () => {
          resolve();
        },
      });
    });
  }
}

function mapStateToProps(state) {
  return {
    vacancy: state.vacancy.get("vacancy"),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    vacancyActions: bindActionCreators(
      { REQUEST_NEW_CANDIDATES, PROCESS_CANDIDATES },
      dispatch
    ),
  };
}
function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, {
  withRef: true,
})(withTranslation()(Invite));
