import React, { Component } from "react";
import afganistanFlag from "../../images/payment/flags/afganistanFlag.svg";
import andorraFlag from "../../images/payment/flags/andorraFlag.svg";

import "./index.css";

export default class HCountry extends Component {
  render() {
    return (
      <div>
        <div className="row nationBox">
          <div className="form-group countryList">
            <select className="form-control selectFlag">
              <option className="afg">Afghanistan</option>
              <option className="and">Andorra</option>
              <option className="ago">Angola</option>
              <option>Antarctica</option>
              <option>Argelia</option>
              <option>Argentina</option>
              <option>Australia</option>
              <option>Austria</option>
              <option>Azerbaijan</option>
              <option>Bahrain </option>
              <option>Belarus</option>
              <option>Belgium</option>
              <option>Belize </option>
              <option>Bermuda </option>
              <option>Bhutan </option>
              <option>Bolivia</option>
              <option>Botswana</option>
              <option>Brazil </option>
              <option>Burkina Faso</option>
              <option>Burundi</option>
              <option>Canada </option>
              <option>Cambodia </option>
              <option>Cameroon </option>
              <option>Cape Verde </option>
              <option>Chile </option>
              <option>China </option>
              <option>Christmas Island </option>
              <option>Cocos (Keeling) Islands </option>
              <option>Comoros </option>
              <option>Colombia </option>
              <option>Costa Rica </option>
              <option>Congo, Republic of (Brazzaville)</option>
              <option>Cook Islands </option>
              <option>Cuba</option>
              <option>Ivory Coast (Cote d’Ivoire) </option>
              <option>Croatia </option>
              <option>Cyprus </option>
              <option>Czech Republic </option>
              <option>Democratic Republic of the Congo (Kinshasa)</option>
              <option>Denmark </option>
              <option>Dominican Republic</option>
              <option>East Timor </option>
              <option>Ecuador </option>
              <option>Egypt</option>
              <option>El Salvador</option>
              <option>England</option>
              <option>Equatorial Guinea </option>
              <option>Ethiopia</option>
              <option>Falkland Islands</option>
              <option>Faroe Islands</option>
              <option>Fiji </option>
              <option>Finland </option>
              <option>France </option>
              <option>French Polynesia </option>
              <option>French Southern Territories</option>
              <option>Gabon</option>
              <option>Gambia</option>
              <option>Germany </option>
              <option>Georgia</option>
              <option>Ghana</option>
              <option>Great Britain </option>
              <option>Greece </option>
              <option>Greenland </option>
              <option>Guatemala</option>
              <option>Haiti</option>
              <option>Holy See </option>
              <option>Honduras</option>
              <option>Hungary</option>
              <option>Iceland</option>
              <option>India</option>
              <option>Indonesia</option>
              <option>Iran</option>
              <option>Iraq</option>
              <option>Ireland</option>
              <option>Israel</option>
              <option>Italy</option>
              <option>Jamaica</option>
              <option>Japan</option>
              <option>Jordan </option>
              <option>Kazakhstan </option>
              <option>Kenya </option>
              <option>Kyrgyzstan </option>
              <option>Laos</option>
              <option>Latvia </option>
              <option>Lebanon </option>
              <option>Letonia</option>
              <option>Libya </option>
              <option>Lithuania </option>
              <option>Luxembourg </option>
              <option>Madagascar</option>
              <option>Malaysia </option>
              <option>Maldives</option>
              <option>Marshall Islands </option>
              <option>Martinique</option>
              <option>Mauritius </option>
              <option>Mexico </option>
              <option>Morocco </option>
              <option>Mozambique </option>
              <option>Namibia </option>
              <option>Netherlands</option>
              <option>Netherlands Antilles </option>
              <option>New Caledonia</option>
              <option>New Zealand</option>
              <option>North Korea</option>
              <option>Northern Mariana Islands </option>
              <option>Norway </option>
              <option>Palestinian territories</option>
              <option>Panama</option>
              <option>Papua New Guinea </option>
              <option>Paraguay</option>
              <option>Peru</option>
              <option>Philippines </option>
              <option>Poland</option>
              <option>Portugal</option>
              <option>Puerto Rico</option>
              <option>Pitcairn Island </option>
              <option>Poland </option>
              <option>Romania</option>
              <option>Russia</option>
              <option>Rwanda</option>
              <option>Saint Kitts and Nevis </option>
              <option>Saint Vincent and the Grenadines</option>
              <option>Sao Tome and Principe </option>
              <option>Saudia Arabia </option>
              <option>Scotland</option>
              <option>Serbia</option>
              <option>Sierra Leone</option>
              <option>Slovakia</option>
              <option>Slovenia </option>
              <option>Solomon Islands </option>
              <option>Somalia</option>
              <option>South Africa </option>
              <option>South Sudan</option>
              <option>Spain</option>
              <option>Suriname </option>
              <option>Swaziland</option>
              <option>Sweden </option>
              <option>Switzerland </option>
              <option>Syria </option>
              <option>Tanzania</option>
              <option>Tajikistan</option>
              <option>Thailand </option>
              <option>Timor-Leste (East Timor) </option>
              <option>Trinidad and Tobago </option>
              <option>Tunisia</option>
              <option>Turkey </option>
              <option>Turks and Caicos Islands </option>
              <option>Ukraine </option>
              <option>United Arab Emirates </option>
              <option>United Kingdom </option>
              <option>United States</option>
              <option>Uruguay</option>
              <option>Vatican City State </option>
              <option>Virgin Islands (British) </option>
              <option>Virgin Islands (American) </option>
              <option>Venezuela</option>
              <option>Wales</option>
              <option>Western Sahara </option>
              <option>Yemen</option>
              <option>Zimbabwe </option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}
