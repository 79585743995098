import React, { Component } from "react";
import Slider from "react-slick";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import logoWhite from "../../images/logoWhite.svg";
import lineGray from "../../images/lineGray.svg";
import HButton from "../../components/hbutton";
import { withTranslation } from "react-i18next";

import "./index.css";

class NullButton extends Component {
  render() {
    return null;
  }
}
const nullButton = <NullButton />;

class Start extends Component {
  constructor(props) {
    super(props);
    this.renderSlide = this.renderSlide.bind(this);
    this._showLogin = this._showLogin.bind(this);
    const { t } = this.props;
    this.sliders = [
      {
        header: t("Secure"),
        text: `${t(
          "Your information is safe with us. We use state of the art encryption technology"
        )}.`,
      },
      {
        header: t("Increase productivity"),
        text: t(
          "Herman avoids pain and saves time for all. (HR managers, hiring managers & candidates)"
        ),
      },
      {
        header: t("Certainty"),
        text: `${t(
          "Get more certainty and increase quality in hiring. The right candidate for the right position"
        )}.`,
      },
      {
        header: t("AI & Cognitive"),
        text: t(
          "Patented cognitive technology and predictive AI analytics allow unprecedented depth of insights"
        ),
      },
    ];
  }
  componentDidMount() {}
  render() {
    const { t } = this.props;
    return (
      <div className="loginBackground">
        <div className="container">
          <div className="row justify-content-center">
            <img role="presentation" className="logo center" src={logoWhite} />
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 sliderWhite">
              <Slider
                nextArrow={nullButton}
                prevArrow={nullButton}
                autoplay={true}
                autoplaySpeed={5000}
                dots={true}
                infinite={true}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
              >
                {this.sliders.map((s, i) => this.renderSlide(s, i))}
              </Slider>
            </div>
          </div>
          <div className="row justify-content-center margin-top-20">
            <div className="col-md-6 text-center white ">
              {t("Welcome Hiring Manager")}
            </div>
          </div>
          <div className="row justify-content-center margin-top-20">
            <div className="col-md-6 col-12">
              <HButton type="primary" onClick={this._showLogin}>
                {t("Hiring Manager")}
              </HButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderSlide(slide, index) {
    return (
      <div key={index}>
        <div className="slide header">{slide.header}</div>
        <div className="slide text">{slide.text}</div>
      </div>
    );
  }
  _showLogin() {
    this.props.actions.push("/login");
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ push }, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Start));
