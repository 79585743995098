import React, { Component } from "react";
import "./index.css";

export default class HImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = { file: "", imagePreviewUrl: "" };
  }

  _handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
    console.log("handle uploading-", this.state.file);
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }
  get selectedFile() {
    return this.state.file;
  }

  render() {
    let { imagePreviewUrl } = this.state;
    let imagePreview = null;
    if (imagePreviewUrl) {
      imagePreview = <img src={imagePreviewUrl} />;
    } else if (this.props.image) {
      imagePreview = <img src={this.props.image} />;
    }
    return (
      <div>
        <div className="previewComponent">
          <form onSubmit={(e) => this._handleSubmit(e)}>
            <input
              className="fileInput"
              type="file"
              onChange={(e) => this._handleImageChange(e)}
            />
          </form>
          <div className="imgPreview">{imagePreview}</div>
        </div>
      </div>
    );
  }
}
