import React, { Component } from "react";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Chart from "chart.js";
import { StickyContainer, Sticky } from "react-sticky";

import lineGraph from "../../images/lineGraph.svg";
import verticalLineGraph from "../../images/verticalLineGraph.svg";
import verticalLineGraphActive from "../../images/verticalLineGraphActive.svg";
import after from "../../images/after.svg";
import next from "../../images/next.svg";
import arrowSelect from "../../images/arrowSelect.svg";
import DetailCandidate from "../detailCandidate";
import { REQUEST_INTERVIEWS } from "../../reducers/candidate";
import { REQUEST_FEEDBACK_TEXT } from "../../reducers/vacancy";
import "./index.css";
import playRec from "../../images/playRec.svg";
import KeyQuestion from "../keyQuestion";

class GraphCandidates extends Component {
  constructor(props) {
    super(props);
    this.renderAvatar = this.renderAvatar.bind(this);
    this.renderPerformance = this.renderPerformance.bind(this);
    this.openCandidate = this.openCandidate.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      selected: false,
      startIndex: 0,
      visibleItems: this.getElements(),
      showKeyQuestion: false,
      videoKeyQuestion: null,
    };
    this.keyQuestionVideo = React.createRef();
  }
  getElements() {
    const width = window.innerWidth;
    if (width < 900 && width > 700) {
      return 6;
    }
    if (width <= 700 && width > 590) {
      return 5;
    }
    if (width <= 590 && width > 490) {
      return 4;
    }
    if (width <= 490 && width > 445) {
      return 3;
    }
    if (width <= 445) {
      return 3;
    }
    return 8;
  }

  getCandidates() {
    let { visibleItems, startIndex } = this.state;
    let { candidates } = this.props;
    let showCandidates = candidates.slice(
      startIndex,
      visibleItems + startIndex
    );
    let mustShow =
      candidates.size > visibleItems ? visibleItems : candidates.size;
    let currentCandidates = showCandidates.size;
    if (mustShow != showCandidates.size) {
      for (let i = 0; i < mustShow - currentCandidates; i++) {
        showCandidates = showCandidates.push(candidates.get(i));
      }
    }
    return showCandidates;
  }

  componentDidMount() {
    if (window.location.href.indexOf("beta") >= 0) {
      this.beta = true;
    }
    this.props.candidateActions.REQUEST_INTERVIEWS({
      vacancy: this.props.match.params.uuid,
    });
    this.props.candidateActions.REQUEST_FEEDBACK_TEXT();
    let candidates = this.getCandidates();
    let ctx = this.refs.canvas.getContext("2d");
    let grd = ctx.createLinearGradient(150.0, 0.0, 150.0, 300.0);

    // Add colors
    grd.addColorStop(0.0, "rgba(80, 227, 194, 1.000)");
    grd.addColorStop(0.91, "rgba(0, 128, 255, 0.000)");

    this.config = {
      type: "line",
      data: {
        labels: candidates.map((c) => c.name).toArray(),
        datasets: [
          {
            label: "Performance",
            backgroundColor: grd,
            borderColor: "rgb(80,227,194)",
            data: candidates.map((c) => c.value).toArray(),
            fill: true,
            pointBorderColor: "rgb(255,255,255)",
            pointBackgroundColor: "rgb(255,255,255)",
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        layout: {
          padding: {
            top: 5,
          },
        },
        responsive: true,
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              display: false,
              scaleLabel: {
                display: false,
                labelString: "Candidate",
              },
            },
          ],
          yAxes: [
            {
              display: false,
              scaleLabel: {
                display: false,
                labelString: "Performance",
              },
              ticks: {
                min: 0,
                max: 150,
              },
            },
          ],
        },
      },
    };
    this.line = new Chart(ctx, this.config);
  }
  componentWillReceiveProps(nextProps) {
    let candidates = this.getCandidates();
    const selectedCandidate = candidates.findIndex(
      (c) => c.get("uuid") === nextProps.match.params.uuidDetail
    );
    if (
      nextProps.match.params.uuidDetail &&
      candidates &&
      selectedCandidate == -1
    ) {
      let { visibleItems, startIndex } = this.state;
      let originalCandidates = nextProps.candidates;
      const originalIndex = originalCandidates.findIndex(
        (c) => c.get("uuid") === nextProps.match.params.uuidDetail
      );
      this.setState({
        selected: 0,
        startIndex: originalIndex,
      });
    }
    if (
      nextProps.match.params.uuidDetail &&
      candidates &&
      this.state.selected != selectedCandidate
    ) {
      if (selectedCandidate >= 0) {
        this.setState({
          selected: selectedCandidate,
        });
      }
    }
  }
  componentDidUpdate() {
    let candidates = this.getCandidates();

    let ctx = this.refs.canvas.getContext("2d");
    let grd = ctx.createLinearGradient(150.0, 0.0, 150.0, 300.0);

    // Add colors
    grd.addColorStop(0.0, "rgba(80, 227, 194, 1.000)");
    grd.addColorStop(0.91, "rgba(0, 128, 255, 0.000)");

    this.line.data = {
      labels: candidates
        .map((c) => c.getIn(["candidate", "first_name"]))
        .toArray(),
      datasets: [
        {
          label: "Performance",
          backgroundColor: grd,
          borderColor: "rgb(80,227,194)",
          data: candidates.map((c) => c.get("score")).toArray(),
          fill: true,
          pointBorderColor: "rgb(255,255,255)",
          pointBackgroundColor: "rgb(255,255,255)",
        },
      ],
    };
    this.line.update(0);

    if (
      this.props.match.params.uuidDetail &&
      candidates &&
      !this.state.firstLoad
    ) {
      const selectedCandidate = candidates.findIndex(
        (c) => c.get("uuid") === this.props.match.params.uuidDetail
      );
      if (selectedCandidate >= 0) {
        this.setState({
          selected: selectedCandidate,
          firstLoad: true,
        });
      }
    }
  }

  render() {
    const { detail, interview } = this.props;
    let candidates = this.getCandidates();
    let width = candidates.size * 100;
    let showDetail =
      detail &&
      this.state.selected !== false &&
      candidates.getIn([this.state.selected]) != null;
    const screen = this.props.location.pathname.split("/");
    return (
      <div className="graph-candidates">
        {this.state.selected !== false && this.state.videoKeyQuestion ? (
          <KeyQuestion
            ref={this.keyQuestionVideo}
            show={this.state.showKeyQuestion}
            onClose={this.closeKeyQuestion.bind(this)}
            video={this.state.videoKeyQuestion}
          />
        ) : null}
        <StickyContainer className="sticky">
          <Sticky isSticky="true">
            {({
              style,
              isSticky,
              wasSticky,
              distanceFromTop,
              distanceFromBottom,
              calculatedHeight,
            }) => {
              return (
                <div style={style} className="graph-header">
                  <div className="graph-title">
                    {`${interview.get("company")} ${interview.get("name")}`}
                  </div>
                  <div className="graph-cursor">
                    <img
                      className="after"
                      src={after}
                      onClick={this.previous}
                    />
                    <img className="before" src={next} onClick={this.next} />
                  </div>
                  <div className="graph-candidates-avatars">
                    {candidates.map((c, i) => this.renderAvatar(c, i))}
                  </div>
                </div>
              );
            }}
          </Sticky>
          <div className={showDetail ? "graph-hidden" : "graph-content"}>
            <div className="graph-separator">
              <img src={lineGraph} />
            </div>
            <div className="graph-candidates-performance">
              {candidates.map((c, i) => this.renderPerformance(c, i))}
            </div>
            <div className="graph-canvas">
              <div style={{ width: width - 100 }}>
                <canvas ref="canvas" />
              </div>
            </div>
            <div className="graphMaster">
              <div className="boxGraph" onClick={this.openCandidate}>
                <img className="" src={arrowSelect} />
                {this.renderNameCandidate(
                  candidates.getIn([this.state.selected])
                )}
                {/*this.props.candidates.getIn([i,"candidate","first_name"])*/}
              </div>
            </div>
          </div>
          {showDetail ? (
            <div
              className="onlyPrint"
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                justifyContent: "center",
                background: "#fbfbfb",
              }}
            >
              {`${interview.get("company")} ${interview.get("name")}`}
            </div>
          ) : null}
          {showDetail ? (
            <div
              className="onlyPrint page-printer-avatar"
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                justifyContent: "center",
                background: "#fbfbfb",
              }}
            >
              {this.renderAvatar(
                candidates.getIn([this.state.selected]),
                this.state.selected
              )}
            </div>
          ) : null}
          {showDetail ? (
            <DetailCandidate
              candidate={candidates.getIn([this.state.selected, "candidate"])}
              interviewId={candidates.getIn([this.state.selected, "uuid"])}
            />
          ) : null}
        </StickyContainer>
      </div>
    );
  }

  next() {
    const { startIndex } = this.state;
    const { candidates } = this.props;
    let nextIndex = startIndex + 1 < candidates.size ? startIndex + 1 : 0;
    console.log(this.state.selected);
    this.setState(
      {
        startIndex: nextIndex,
      },
      () => {
        this.openCandidate();
      }
    );
  }

  previous() {
    let { startIndex } = this.state;
    const { candidates } = this.props;
    let nextIndex = startIndex - 1 >= 0 ? startIndex - 1 : candidates.size - 1;
    this.setState(
      {
        startIndex: nextIndex,
      },
      () => {
        this.openCandidate();
      }
    );
  }

  renderPerformance(c, i) {
    const { selected } = this.state;
    return (
      <div
        key={i}
        className={
          "graph-performance " + (i == selected ? "active" : "inactive")
        }
        onClick={this.select.bind(this, i)}
      >
        <div className="number">{`${c.get("score")}%`}</div>
        <div className="line">
          <img
            src={i == selected ? verticalLineGraphActive : verticalLineGraph}
          />
        </div>
      </div>
    );
  }
  renderAvatar(c, i) {
    const { selected, candidates, interview } = this.state;
    let avatar = c.getIn(["candidate", "image_url"]);
    if (avatar == "/images/placeholder.svg") {
      avatar = c.getIn(["random_image"]);
      if (!avatar || avatar.indexOf("cloudinary") > 1) {
        avatar = "/images/placeholder.svg";
      }
    }

    return (
      <div
        key={i}
        className={
          "graph-candidate-avatar " + (i == selected ? "active" : "inactive")
        }
        onClick={this.select.bind(this, i)}
      >
        <div className="graph-avatar">
          <img className="imgAvatar" src={avatar} />
          {this.renderKeyQuestion(c) ? (
            <img
              className="play"
              src={playRec}
              onClick={this.showKeyQuestion.bind(this, c)}
            />
          ) : null}
        </div>
        <div className="graph-avatar-name" style={{ height: 70 }}>
          {`${c.getIn(["candidate", "first_name"])} ${c.getIn([
            "candidate",
            "last_name",
          ])}`}
        </div>
        <div className="graph-avatar-name">{`${c.getIn(["score"])}%`}</div>
      </div>
    );
  }
  showKeyQuestion(c) {
    this.setState({
      showKeyQuestion: true,
      videoKeyQuestion: this.renderKeyQuestion(c),
    });
    this.keyQuestionVideo.current.play();
  }
  closeKeyQuestion() {
    this.setState({
      showKeyQuestion: false,
    });
  }
  renderNameCandidate(c) {
    if (!c) {
      return null;
    }
    return (
      <div className="inGraph">
        {`${c.getIn(["candidate", "first_name"])} ${c.getIn([
          "candidate",
          "last_name",
        ])}`}
        <br />
        <span className="subGraph"> </span>
      </div>
    );
  }
  renderKeyQuestion(c) {
    if (!c) {
      return null;
    }
    return c.get("key_question");
  }
  select(i) {
    this.setState(
      {
        selected: i,
      },
      () => {
        this.openCandidate();
      }
    );
  }
  openCandidate() {
    let candidates = this.getCandidates();
    if (this.beta) {
      this.props.actions.push(
        `/graph/${this.props.match.params.uuid}/detail/${candidates.getIn([
          this.state.selected,
          "uuid",
        ])}/beta`
      );
    } else {
      this.props.actions.push(
        `/graph/${this.props.match.params.uuid}/detail/${candidates.getIn([
          this.state.selected,
          "uuid",
        ])}`
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    candidates: state.candidate.getIn(["interviews", "interview_set"]),
    interview: state.candidate.get("interviews"),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    candidateActions: bindActionCreators(
      { REQUEST_INTERVIEWS, REQUEST_FEEDBACK_TEXT },
      dispatch
    ),
    actions: bindActionCreators({ push }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GraphCandidates);
