/* global videojs*/
import React, { Component } from "react";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import playRec from "../../images/playRec.svg";
import { CircularProgressbar } from "react-circular-progressbar";
import { REQUEST_UPDATE_VIDEO } from "../../reducers/vacancy";
import { withTranslation } from "react-i18next";
import "./index.css";
import instructions from "../../images/instructions.png";
const renderVideoBlue = {};
const renderVideoBlack = {};
const renderVideoPlay = {};

const levels = [1, 2, 3];
const sections = [];
const maxTime = 120;
class VideoRecorder extends Component {
  constructor(props) {
    super(props);
    this._nextSection = this._nextSection.bind(this);
    this._previousSection = this._previousSection.bind(this);
    this._initVideo = this._initVideo.bind(this);
    this._record = this._record.bind(this);
    this._stopRecord = this._stopRecord.bind(this);
    this._stopRecorded = this._stopRecorded.bind(this);
    this._playRecorded = this._playRecorded.bind(this);
    this._saveRecord = this._saveRecord.bind(this);
    this.submitVideoFile = this.submitVideoFile.bind(this);
    this.state = {
      modify: false,
      visible: props.visible || false,
      section: 0,
      preview: false,
      previewUrl: "",
      playing: false,
      askAgain: false,
    };
    if (props.vacancy) {
      this.state = Object.assign(
        {},
        this.state,
        this.componentSetFromServer(props)
      );
    }
    this.videoNode = React.createRef();
    this.previewNode = React.createRef();
    this.videoFile = React.createRef();
  }
  componentDidMount() {
    // instantiate Video.js
    this._initVideo(this.videoNode);
  }
  componentWillReceiveProps(newProps) {
    if (
      newProps.vacancy.get("uuid") != this.props.vacancy.get("uuid") &&
      newProps.vacancy.get("uuid") &&
      this.state.modify == false
    ) {
      this.setState(this.componentSetFromServer(newProps));
    }
  }
  componentSetFromServer(props) {
    if (
      props.vacancy.get("video_url") &&
      props.vacancy.get("video_url") !== "/images/placeholder.svg"
    ) {
      return {
        preview: true,
        previewUrl: props.vacancy.get("video_url"),
        playing: false,
        section: 2,
      };
    }
    return {};
  }
  _initVideo(node) {
    if (node.current) {
      const videoNode = node.current;
      const player = videojs(
        videoNode,
        {
          plugins: {
            record: {
              audio: true,
              video: true,
              maxLength: 120,
              debug: false,
            },
          },
        },
        function onPlayerReady() {
          console.log("onPlayerReady", this);
        }
      );
      // error handling
      player.on("deviceError", () => {
        if (player.deviceErrorCode.message.indexOf("denied") >= 0) {
          console.log("Denied");
          this.setState({
            askAgain: true,
          });
        }
        console.log("device error:", player.deviceErrorCode);
      });
      player.on("error", function (error) {
        console.log("error:", error);
      });
      // user clicked the record button and started recording
      player.on("startRecord", function () {
        console.log("started recording!");
      });
      // user completed recording and stream is available
      player.on("finishRecord", () => {
        // the blob object contains the recorded data that
        // can be downloaded by the user, stored on server etc.
        console.log("finished recording: ", player);
        let preview;
        if (player.recordedData.video) {
          preview = URL.createObjectURL(player.recordedData.video);
        } else {
          preview = URL.createObjectURL(player.recordedData);
        }
        this.setState({
          previewUrl: preview,
        });
      });
      this.player = player;
      this.player.record().getDevice();
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    const { t } = this.props;
    const { visible, section, preview, previewUrl, askAgain } = this.state;
    return (
      <div className="bodyVideo">
        <div className="alt-instructions">
          <div className="col-md-4 col-xs-12 text-center">
            <div className="btn btn-primary reviewButton emailBatchButton emailButton">
              {t("Choose File")}
              <input
                className="emailBatchInput"
                accept=".mp4,.mpg,mov"
                ref={this.videoFile}
                type="file"
                onChange={this.submitVideoFile}
              />
            </div>
          </div>
        </div>        
      </div>
    );
  }
  renderVideoSections() {
    const { section } = this.state;
    switch (section) {
      case 0:
        return this.renderVideoBlue();
      case 1:
        return this.renderVideoBlack();
      case 2:
        return this.renderVideoPlay();
      default:
        return null;
    }
  }

  renderVideoBlue() {
    const { renderVideoBlue } = this.state;
    return (
      <div>
        <div className="row justify-content-end rowBlackSkip">
          <button type="button" className="btn btn btn-link btnSkip">
            {this.props.t("Skip")}
          </button>
        </div>
        <div className="boxBlue">
          {this.props.t(
            "I recommend recording at no more than 2 minutes. Press the button below when you are ready to start recording"
          )}
          .
        </div>
        <div className="btnVideoRec" onClick={this._record}>
          <div className="btnRec"></div>
        </div>
      </div>
    );
  }
  renderVideoBlack() {
    const { renderVideoBlack, recording } = this.state;
    return (
      <div>
        <div className="row justify-content-end rowBlackSkip">
          <button type="button" className="btn btn-link btnSkip">
            {this.props.t("Skip")}
          </button>
        </div>
        <div className="boxBlack">
          {this.props.t(
            "Try to present yourself in a welcoming manner, the company context and the challenges of the position"
          )}
          .
        </div>
        <div className="btnVideoRec recording" onClick={this._stopRecord}>
          <CircularProgressbar
            className="progressbar-red"
            text={`${recording}`}
            value={Math.floor((recording * 100) / maxTime)}
            strokeWidth={6}
          />
        </div>
      </div>
    );
  }
  renderVideoPlay() {
    const { renderVideoPlay, playing } = this.state;
    let imageButton = playRec;
    return (
      <div>
        <div className="row justify-content-end rowBlackSkip">
          <button type="button" className="btn btn btn-link btnSkip">
            Skip
          </button>
        </div>
        <div className="boxPlayRec" style={{ display: playing ? "none" : "" }}>
          <div className="playRec" onClick={this._playRecorded}>
            <img src={playing ? "" : playRec} />
          </div>
        </div>
        <div className="row justify-content-between btnsRecorder">
          <div className="boxBtnReRecord">
            <button
              type="button"
              className="btn btn-primary btnReRecord"
              onClick={this._record}
            >
              {this.props.t("RE-RECORD")}
            </button>
          </div>
          <div className="boxBtnReRecord">
            <button
              type="button"
              className="btn btn-primary btnSaveVideo"
              onClick={this._saveRecord}
            >
              {this.props.t("SAVE VIDEO")}
            </button>
          </div>
        </div>
      </div>
    );
  }
  _playRecorded() {
    this.previewNode.current.play();
    this.setState({
      playing: true,
    });
  }
  _stopRecorded() {
    this.setState({
      playing: false,
    });
  }
  _record() {
    if (this.previewNode.current) {
      this.previewNode.current.pause();
    }
    this.player.record().start();
    let recordingStart = new Date();
    this.setState({
      section: 1,
      modify: true,
      recording: 0,
      preview: false,
      playing: false,
    });
    this.timer = setInterval(() => {
      let current = Math.floor((new Date() - recordingStart) / 1000);
      if (current > maxTime) {
        clearInterval(this.timer);
        this.player.record().stop();
        this.setState({
          recording: current,
          section: 2,
          preview: true,
        });
      } else {
        this.setState({
          recording: current,
        });
      }
    }, 500);
  }
  _stopRecord() {
    clearInterval(this.timer);
    this.player.record().stop();
    this.setState({
      section: 2,
      preview: true,
    });
  }
  _nextSection() {
    this.setState({
      section: this.state.section + 1,
    });
  }
  submitVideoFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      // No more than 20 MB
      if(event.target.files[0].size > 20971520){
        return
      }
      this.props.vacancyActions.REQUEST_UPDATE_VIDEO({
        uuid: this.props.vacancy.get("uuid"),
        video: event.target.files[0],
        onComplete: () => {
          this.props.push("/email");
        },
      });
    }
  }
  _saveRecord() {
    if (this.player.recordedData) {
      let preview;
      if (this.player.recordedData.video) {
        preview = this.player.recordedData.video;
      } else {
        preview = this.player.recordedData;
      }
      this.props.vacancyActions.REQUEST_UPDATE_VIDEO({
        uuid: this.props.vacancy.get("uuid"),
        video: preview,
        onComplete: () => {
          this.props.push("/email");
        },
      });
    } else {
      this.props.push("/email");
    }
  }
  _previousSection() {}
}

function mapStateToProps(state) {
  return {
    vacancy: state.vacancy.get("vacancy"),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ push }, dispatch),
    vacancyActions: bindActionCreators({ REQUEST_UPDATE_VIDEO }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(VideoRecorder));
