import React, { Component } from "react";
import backHeader from "../../images/feedback/backHeader.svg";
import circleBack from "../../images/feedback/circleBack.svg";
import cancelFeed from "../../images/feedback/cancelFeed.svg";
import starDetail from "../../images/starDetail.svg";
import after from "../../images/after.svg";
import next from "../../images/next.svg";
import { withTranslation } from "react-i18next";
import "./index.css";

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, "g"), replace);
}

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  render() {
    const { show, assessment, index, feedback, candidate, t } = this.props;

    if (!assessment || !assessment[index]) {
      return null;
    }
    const competency = assessment[index];
    let feedbackText = "";
    if (feedback[competency.key]) {
      let feedbackObj = feedback[competency.key].feedbackFull.find(
        (f) => f.percentile == competency.percentile
      );
      if (feedbackObj) {
        const isSpanish = this.props.i18n.language.indexOf("es") >= 0;
        feedbackText = isSpanish ? feedbackObj.textES : feedbackObj.text;
        if (feedbackText && candidate) {
          feedbackText = replaceAll(
            feedbackText,
            "%name%",
            candidate.get("first_name")
          );
        }
      }
    }
    return (
      <div
        className="modal fade show feedbackModal page-break-inside-avoid"
        style={{ display: show ? "block" : "none", width: "100vw" }}
      >
        <div className="modal-dialog">
          <div className="modal-content feedContainer">
            <div className="canContPrincipalFeed">
              <div className="contTitleBack">
                <div className="contFeedback">
                  <img
                    className="cancelSimbol cancelRight"
                    src={cancelFeed}
                    onClick={() => this.props.onClose()}
                  />
                  <div className="titleBack">{t(competency.name)}</div>
                </div>
                <div className="graph-cursor">
                  <img
                    className="after"
                    src={after}
                    onClick={() => this.props.previous()}
                  />
                  <img
                    className="before"
                    src={next}
                    onClick={() => this.props.next()}
                  />
                </div>
                <div className="scoreBox">
                  <span className="scoreFeed">{competency.score}</span>
                  <p className="txtScore">{t("score")}</p>
                </div>

                <p className="porcentageBack">
                  {Math.round((competency.score * 10000) / 6) / 100}% of{" "}
                  {Math.round((competency.target * 10000) / 6) / 100}%
                </p>
              </div>
              <div className="row">
                <div className="col">
                  <p className="txtDescription">{feedbackText}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  next() {}
  previous() {}
  before() {}
}

export default withTranslation()(Feedback);
