import React, { Component } from "react";
import arcChart from "./arcChart";

const colors = ["#003878", "#0077ff", "#9012fe", "#ffbd00", "#ff0d70"];

export default class HArcGraph extends Component {
  componentDidMount() {
    this.chart = arcChart.create({
      data: this.props.psycho
        .map((p, i) => ({
          value: p.get("percentile") * 100,
          fill: i < colors.length ? colors[i] : colors[colors.length - 1],
        }))
        .toArray(), //see data section below for compatible data format
      container: this.refs.graph,
      compact: false,
      plotQuadrant: {
        start: 1,
        end: 4,
      },
    });
  }

  render() {
    return <div ref="graph" style={{ height: "100%" }}></div>;
  }
}
