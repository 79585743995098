import React, { Component } from "react";
import cancelFeed from "../../images/feedback/cancelFeed.svg";
import { withTranslation } from "react-i18next";
import "./index.css";

class Handbook extends Component {
  constructor(props) {
    super(props);
    this.createTexts(this.props.t);
  }

  componentWillReceiveProps(newProps) {
    this.createTexts(newProps.t);
  }

  render() {
    const { show, t } = this.props;
    console.log("Render");
    return (
      <div
        className=" modal fade show "
        style={{ display: show ? "block" : "none", width: "100vw" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content modalHandbook">
            <img
              className="cancelSimbol"
              src={cancelFeed}
              onClick={() => this.close()}
            />
            <div className="handbook_wrapper">
              <div className="handbook_side">
                <ul>
                  <li>{t("Competency model")}</li>
                  {this.competencies.map((element) => (
                    <li key={element.key}>{element.value}</li>
                  ))}
                </ul>
              </div>
              <div className="handbook_content">
                <div className="handbook_title">
                  {t("The Herman Behavioral Competency Model")}:
                </div>
                <div className="handbook_center">
                  {t("The Herman behavioral interview focuses on both")}:
                </div>
                <div className="handbook_pills ">
                  <div className="handbook_pill handbook_first_pill">
                    {t("Core competencies")}
                  </div>
                  <div className="handbook_pill">
                    {t("Managerial competencies")}
                  </div>
                </div>
                <div className="hanbook_bold handbook_center">
                  {t("Core competences")}
                </div>
                <div className="handbook_center">
                  {t("The core competencies Herman evaluates are")}
                </div>
                <div className="handbook_list_wrapper">
                  <ul className="handbook_list">
                    <li>
                      <span>1.</span>
                      {t("Strategic Thinking")}
                    </li>
                    <li>
                      <span>2.</span>
                      {t("Achievement Orientation")}
                    </li>
                    <li>
                      <span>3.</span>
                      {t("Leadership")}
                    </li>
                    <li>
                      <span>4.</span>
                      {t("Teamwork & Collaboration")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  close() {
    this.props.onClose();
  }
  createTexts(t) {
    console.log("Loading texts");
    this.competencies = [
      { key: "TL", value: t("Leadership") },
      { key: "AO", value: t("Results Orientation") },
      { key: "ST", value: t("Strategic Thinking") },
      { key: "TC", value: t("Teamwork & Collaboration") },
      { key: "DO", value: t("Developing Others") },
      { key: "EI", value: t("Emotional Intelligence") },
      { key: "CO", value: t("Commercial Drive") },
      { key: "CM", value: t("Change Management") },
    ];
  }
  renderSection(e) {
    const { t } = this.props;
    return (
      <div>
        <div>{e.title}</div>
        <div>{e.description}</div>
        <ul>
          {e.firstPoints.map((p) => (
            <li>{p}</li>
          ))}
        </ul>
        <div>{e.summaryTitle}</div>
        <div>
          <div>
            <div>{t("Level")}</div>
            <div>{t("Summary Description")}</div>
            <div>Seniority</div>
          </div>
          {e.summary.map((t) => (
            <div>
              <div>{t.level}</div>
              <div>{t.description}</div>
              <div>{t.seniority}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
Handbook.defaultProps = {
  show: true,
};
export default withTranslation()(Handbook);
