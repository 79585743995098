export default {
  createTexts: (t) => {
    const skillsHeaders = [
      [t("Jr. Managers"), t("Professionals")],
      [t("Managers")],
      [t("Sr. Managers"), t("Directors")],
      [t("Vice Presidents"), t("Sr. Vice Presidents")],
      [t("C - Level")],
    ];
    const skillsTooltip = {
      TL: [
        [t("Stays off the side"), t("Makes objectives known")],
        [t("States directly what to do"), t("Picks the good team members")],
        [
          t("Democratic leader who provides support"),
          t("Builds high performance teams"),
        ],
        [
          t("Empowers team members"),
          t("Assess team's strengths and weaknesses"),
        ],
        [t("Coach-leader"), t("Builds complementary and diverse teams")],
        [
          t("Visionary leader"),
          t("Builds diverse teams and superior engagement"),
        ],
      ],
      AO: [
        [
          t("Sometimes produces a good job"),
          t("Can align with the objectives set by others"),
        ],
        [
          t("Committed to achieve good results"),
          t("Delivers good results depending on context"),
        ],
        [
          t("Plans ahead to deliver consistent results"),
          t("Consistently meets targets"),
        ],
        [
          t("Exceeds results on a consistent manner"),
          t("Plans and controls for changing environments"),
        ],
        [
          t("Implements best practices, builds a network of allies"),
          t(
            "Gathers information from the market to produce results despite context"
          ),
        ],
        [
          t("Establishes the new benchmark in the market"),
          t("Becomes the reference in the industry"),
        ],
      ],
      ST: [
        [
          t("Occasionally participates in strategy creation"),
          t("Focus on what needs to be done in the very short term"),
        ],
        [
          t("Understands the strategy of the business unit or area"),
          t("Can plan to follow a strategic plan set in the short term"),
        ],
        [
          t("Fully integrates the company strategy into his team's activities"),
          t(
            "Does not over plan, but can have a short to medium term strategic perspective"
          ),
        ],
        [
          t("Develops the strategy of a business unit"),
          t("Can plan and align for the medium term"),
        ],
        [
          t("In charge of the organization's strategy"),
          t("Defines and develops a long-term vision"),
        ],
        [
          t("Designs industry-distinctive strategies"),
          t("Defines very longer-term planning and vision"),
        ],
      ],
      TC: [
        [
          t("Supports others when not too busy"),
          t("Can help, but only when asked"),
        ],
        [
          t("Participates in teams"),
          t("Provides information and support when asked"),
        ],
        [
          t(
            "Actively participates in teams, even if there are no immediate personal benefits"
          ),
          t("Provides diverse perspective in the discussion"),
        ],
        [
          t("In charge of building teams"),
          t("Brings people from other areas into the discussion"),
        ],
        [
          t("Creates multi-disciplinary teams and builds alliances"),
          t("Shares best practices across the business"),
        ],
        [
          t("In charge of creating a culture of collaboration"),
          t(
            "Builds organizational incentives to foster teamwork and collaboration"
          ),
        ],
      ],
      DO: [
        [
          t("Provides little guidance"),
          t("It is ok to let collaborators to learn by their own"),
        ],
        [
          t("Understands the gaps and needs of the team"),
          t("Provides on the spot feedback"),
        ],
        [
          t("Uses novel tools and best practices"),
          t("Provides feedback when necessary"),
        ],
        [
          t("Seeks to develop the team as a whole"),
          t("Provides structured feedback to the team"),
        ],
        [
          t(
            "Generates commitment to self-development and development of others"
          ),
          t("Creates a culture of development"),
        ],
        [
          t("Comprehensive focus on development"),
          t("Implements best practices and identifies potential"),
        ],
      ],
      EI: [
        [t("Sees emotions as an inconvenience"), t("Prefers to hide emotions")],
        [
          t("Does not pay too much attention to people's emotions"),
          t("Prefers to deal with emotions later, results first"),
        ],
        [
          t("May take additional time to recover from setbacks"),
          t("Encourages excellence and performance"),
        ],
        [
          t("Aware about emotions and in control of self"),
          t("Connects personally and professionally"),
        ],
        [
          t("Manages emotions despite context"),
          t("Connects and identifies emotions on others"),
        ],
        [
          t("Expresses emotions in a controlled fashion"),
          t("Fully understands feelings and its causes"),
        ],
      ],
      CO: [
        [
          t("Not directly responsible for results"),
          t("Little impact on outcomes"),
        ],
        [
          t("Some interconnection with business results"),
          t("Little involvement in commercial execution"),
        ],
        [
          t("Looks for business opportunities"),
          t("Directly supports commercial plans"),
        ],
        [
          t("Explores opportunities and new markets"),
          t("Sophisticated analysis and commercial approach"),
        ],
        [
          t("Directly responsible for commercial success"),
          t("Systemic focus on analysis"),
        ],
        [
          t("Defines the commercial strategy for the business"),
          t("Manages multifunctional teams to meet goals"),
        ],
      ],
      CM: [
        [t("Prefers the tried and true"), t("Prefers little pain as possible")],
        [
          t("Committed to change"),
          t("Executes change initiatives set by others"),
        ],
        [
          t("Leads change initiatives in own area"),
          t("Defines agenda for own unit"),
        ],
        [t("Agent of change"), t("Empowers others to create change")],
        [t("Directly responsible of change agenda"), t("Drives other leaders")],
        [
          t("Projects future change initiatives for the organization"),
          t("Promotes new ideas and adopts best practices"),
        ],
      ],
    };
    return { skillsHeaders, skillsTooltip };
  },
};
