import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import ChoosePlan from "../choosePlan";
import NewVacancy from "../newVacancy";
import VideoRecorder from "../videoRecorder";
import EmailComposer from "../emailComposer";
import Invite from "../invite";
import "./index.css";
import check from "../../images/check.svg";
import { REQUEST_VACANCY } from "../../reducers/vacancy";
import { withTranslation } from "react-i18next";
import { REQUEST_ROLE } from "../../reducers/candidate.js";
import hermanWatermark from "../../images/hermanWatermark.svg";
import { ToastContainer, toast } from "react-toastify";

const sectionMap = {
  new: 1,
  record: 2,
  email: 3,
  invite: 4,
  plan: 5,
  payment: 5,
};
class WizardVacancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: sectionMap[props.screen],
    };
  }
  componentWillReceiveProps(newProps) {
    if (this.props.screen != newProps.screen) {
      this.setState({
        section: sectionMap[newProps.screen],
      });
    }
    if (this.props.uuid != newProps.uuid && newProps.uuid != null) {
      this.requestVacancy();
    }
  }
  componentDidMount() {
    if (this.props.uuid != null) {
      this.requestVacancy();
    } else {
      this.props.vacancyActions.REQUEST_VACANCY(null);
    }
    this.props.candidateActions.REQUEST_ROLE();
  }

  requestVacancy() {
    this.props.vacancyActions.REQUEST_VACANCY(this.props.uuid);
  }
  tryToEmail() {
    console.log("Trying to go to email");
    if (
      !this.props.vacancy.get("subject_email") ||
      !this.props.vacancy.get("email")
    ) {
      toast(
        this.props.t(
          "Please fill and save all the fields on Description section"
        )
      );
      return;
    }
    this.goTo("/invite");
  }
  render() {
    const { t, role } = this.props;
    if (role == "V") {
      return (
        <div className="no-access">
          <div>
            <img className="watermark" src={hermanWatermark} />
          </div>
          <div className="watermark-text">{t("You don't have access ")}</div>
          <div className="watermark-text">{t("to this section ")}</div>
        </div>
      );
    }

    return (
      <div>
        <div className="wizard-bar">
          <div
            className={"wizard-dot " + this.getNavClass(1)}
            onClick={this.goTo.bind(this, "/new")}
          >
            <div className="circle flex justify-center">
              <img src={check} />
            </div>
            <div className="text">{t("Competency")}</div>
          </div>
          <div className={"wizard-line " + this.getNavClass(2)}></div>
          <div
            className={"wizard-dot " + this.getNavClass(2)}
            onClick={this.goTo.bind(this, "/record")}
          >
            <div className="circle flex justify-center">
              <img src={check} />
            </div>
            <div className="text">{t("Welcome Video")}</div>
          </div>
          <div className={"wizard-line " + this.getNavClass(3)}></div>
          <div
            className={"wizard-dot " + this.getNavClass(3)}
            onClick={this.goTo.bind(this, "/email")}
          >
            <div className="circle flex justify-center">
              <img src={check} />
            </div>
            <div className="text">{t("Description")}</div>
          </div>
          <div className={"wizard-line " + this.getNavClass(4)}></div>
          <div
            className={"wizard-dot " + this.getNavClass(4)}
            onClick={this.tryToEmail.bind(this)}
          >
            <div className="circle flex justify-center">
              <img src={check} />
            </div>
            <div className="text">{t("Candidates")}</div>
          </div>
          {/*
          <div className={"wizard-line "+this.getNavClass(5)}> 

          </div>
          
          <div className={"wizard-dot "+this.getNavClass(5) } onClick={this.goTo.bind(this,"/plan")}>
            <div className="circle">
              <img src={check}/>
            </div>
            <div className="text">
              {t('Plan')}
            </div>
          </div>
          */}
        </div>
        <div className="background-modal">{this.renderSection()}</div>
      </div>
    );
  }
  getNavClass(required) {
    const { section } = this.state;
    return required > section ? "inactive" : "";
  }
  goTo(path) {
    if (this.props.uuid) {
      this.props.actions.push(`${path}/${this.props.uuid}`);
    } else {
      this.props.actions.push(path);
    }
  }
  renderSection() {
    const { screen } = this.props;
    switch (screen) {
      case "new":
        return <NewVacancy push={this.goTo.bind(this)} />;
      case "record":
        return <VideoRecorder push={this.goTo.bind(this)} />;
      case "email":
        return <EmailComposer push={this.goTo.bind(this)} />;
      case "invite":
        return <Invite push={this.goTo.bind(this)} />;
      case "payment":
      case "plan":
        return <ChoosePlan push={this.goTo.bind(this)} />;
    }
  }
}
function mapStateToProps(state) {
  return {
    vacancy: state.vacancy.get("vacancy"),
    role: state.candidate.get("role"),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ push }, dispatch),
    vacancyActions: bindActionCreators({ REQUEST_VACANCY }, dispatch),
    candidateActions: bindActionCreators({ REQUEST_ROLE }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(WizardVacancy));
