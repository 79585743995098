import { put, takeLatest } from "redux-saga/effects";
import Immutable from "immutable";
import {
  RESPONSE_VACANCIES,
  REQUEST_VACANCIES,
  RESPONSE_POSITIONS,
  REQUEST_POSITIONS,
  RESPONSE_CONFIG,
  REQUEST_CONFIG,
  RESPONSE_VACANCY,
  REQUEST_VACANCY,
  REQUEST_NEW_VACANCY,
  REQUEST_UPDATE_VACANCY,
  REQUEST_UPDATE_VIDEO,
  REQUEST_UPDATE_LOGO,
  REQUEST_FEEDBACK_TEXT,
  RESPONSE_FEEDBACK_TEXT,
  REQUEST_ARCHIVED,
  PROCESS_CANDIDATES,
  REQUEST_NEW_CANDIDATES,
  LOADING_VACANCIES,
} from "../reducers/vacancy";
import { REQUEST_INTERVIEWS } from "../reducers/candidate";
import ApiVacancy from "../api/vacancy";

export function* requestVacancies() {
  try {
    yield put(LOADING_VACANCIES(true));
    const response = yield ApiVacancy.requestVacancies();
    if (Array.isArray(response.body)) {
      yield put(RESPONSE_VACANCIES(Immutable.fromJS( response.body.reverse())));
    }
  } catch (e) {
    if (e.status == 403) {
      window.location.replace("/login?invalidSession=true");
    }
  }
  console.log("Loaded")
  yield put(LOADING_VACANCIES(false));
}

export function* requestVacancy(data) {
  if (!data.payload) {
    yield put(RESPONSE_VACANCY(Immutable.fromJS({})));
    return;
  }
  const response = yield ApiVacancy.requestVacancy(data.payload);
  console.log(response.body);
  try {
    yield put(RESPONSE_VACANCY(Immutable.fromJS(response.body)));
  } catch (e) {}
}

export function* requestPositions() {
  const response = yield ApiVacancy.requestPositions();
  try {
    if (Array.isArray(response.body)) {
      yield put(RESPONSE_POSITIONS(Immutable.fromJS(response.body)));
    }
  } catch (e) {}
}

export function* requestConfig(data) {
  const response = yield ApiVacancy.requestConfig(data.payload);
  try {
    yield put(RESPONSE_CONFIG(Immutable.fromJS(response.body)));
  } catch (e) {}
}

export function* requestNewUpdateVacancy(data) {
  try {
    let response = null;

    console.log(data);
    if (data.payload.uuid) {
      response = yield ApiVacancy.requestUpdateVacancy(
        data.payload.vacancy,
        data.payload.uuid
      );
    } else {
      response = yield ApiVacancy.requestNewVacancy(data.payload.vacancy);
    }
    if (response.status == 201) {
      data.payload.onComplete({
        success: response.status == 201,
        uuid: response.body.uuid,
      });
      console.log(response.body);
      yield put(REQUEST_VACANCY(response.body.uuid));
    } else {
      data.payload.onComplete({ success: false });
    }
  } catch (e) {
    data.payload.onComplete({ success: false });
  }
}

export function* requestUpdateVideo(data) {
  try {
    const response = yield ApiVacancy.requestUpdateVideo(
      data.payload.uuid,
      data.payload.video
    );
    data.payload.onComplete({ success: response.status == 201 });
  } catch (e) {
    data.payload.onComplete({ success: false });
  }
}

export function* requestUpdateLogo(data) {
  try {
    const response = yield ApiVacancy.requestUpdateLogo(
      data.payload.uuid,
      data.payload.image
    );
    data.payload.onComplete({ success: response.status == 201 });
  } catch (e) {
    data.payload.onComplete({ success: false });
  }
}

export function* requestNewCandidates(data) {
  try {
    const response = yield ApiVacancy.requestNewCandidates(
      data.payload.uuid,
      data.payload.candidates
    );
    data.payload.onComplete({ success: response.status == 201 });
  } catch (e) {
    data.payload.onComplete({ success: false });
  }
}
export function* requestFeedbackText(data) {
  try {
    const response = yield ApiVacancy.requestFeedbackText();
    yield put(RESPONSE_FEEDBACK_TEXT(response.body));
  } catch (e) {}
}
export function* requestProcessCandidates(data) {
  try {
    console.log("Sending file");
    const { uuid, files, onComplete } = data.payload;
    yield ApiVacancy.processCandidates(uuid, files);
    onComplete();
  } catch (e) {
    console.log(e);
  }
}

export function* requestArchived(data) {
  console.log("Archiving", data);

  try {
    yield ApiVacancy.requestUpdateVacancy(
      { archived: data.payload.archived },
      data.payload.uuid
    );
    yield put(REQUEST_INTERVIEWS({ vacancy: data.payload.uuid }));
  } catch (e) {
    console.log(e);
  }
}

export function* watchRequestArchived() {
  yield takeLatest(REQUEST_ARCHIVED.getType(), requestArchived);
}

export function* watchRequestProcessCandidates() {
  yield takeLatest(PROCESS_CANDIDATES.getType(), requestProcessCandidates);
}

export function* watchRequestVacancies() {
  yield takeLatest(REQUEST_VACANCIES.getType(), requestVacancies);
}

export function* watchRequestPositions() {
  yield takeLatest(REQUEST_POSITIONS.getType(), requestPositions);
}

export function* watchRequestNewVacancy() {
  yield takeLatest(REQUEST_NEW_VACANCY.getType(), requestNewUpdateVacancy);
}

export function* watchRequestUpdateVacancy() {
  yield takeLatest(REQUEST_UPDATE_VACANCY.getType(), requestNewUpdateVacancy);
}

export function* watchRequestConfig() {
  yield takeLatest(REQUEST_CONFIG.getType(), requestConfig);
}

export function* watchRequestVacancy() {
  yield takeLatest(REQUEST_VACANCY.getType(), requestVacancy);
}

export function* watchRequestUpdateVideo() {
  yield takeLatest(REQUEST_UPDATE_VIDEO.getType(), requestUpdateVideo);
}

export function* watchRequestUpdateLogo() {
  yield takeLatest(REQUEST_UPDATE_LOGO.getType(), requestUpdateLogo);
}

export function* watchRequestNewCandidates() {
  yield takeLatest(REQUEST_NEW_CANDIDATES.getType(), requestNewCandidates);
}

export function* watchRequestFeedbackText() {
  yield takeLatest(REQUEST_FEEDBACK_TEXT.getType(), requestFeedbackText);
}
