import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Autosuggest from "react-autosuggest";
import { ToastContainer, toast } from "react-toastify";
import { push } from "react-router-redux";
import Inmutable from "immutable";

import closeIcon from "../../images/close.svg";
import HInput from "../../components/hinput";
import HButton from "../../components/hbutton";
import HSelectCircle from "../../components/hselectCircle";
import HCheckbox from "../../components/hcheckbox";
import HCalibrate from "../../components/hcalibrate";
import HCalibrateSolid from "../../components/hcalibrateSolid";
import HToggle from "../../components/htoggle";

import lineLightGray from "../../images/lineLightGray.svg";
import techIcon from "../../images/roles/tech.svg";
import salesIcon from "../../images/roles/sales.svg";
import hrIcon from "../../images/roles/hr.svg";
import developIcon from "../../images/roles/develop.svg";
import generalIcon from "../../images/roles/general.svg";

import techWhiteIcon from "../../images/roles/techWhite.svg";
import salesWhiteIcon from "../../images/roles/salesWhite.svg";
import hrWhiteIcon from "../../images/roles/hrWhite.svg";
import developWhiteIcon from "../../images/roles/developWhite.svg";
import generalWhiteIcon from "../../images/roles/generalWhite.svg";

import globalIcon from "../../images/scope/global.svg";
import localIcon from "../../images/scope/local.svg";
import regionalIcon from "../../images/scope/regional.svg";

import globalWhiteIcon from "../../images/scope/globalWhite.svg";
import localWhiteIcon from "../../images/scope/localWhite.svg";
import regionalWhiteIcon from "../../images/scope/regionalWhite.svg";

import pencilIcon from "../../images/pencil.svg";
import settingsIcon from "../../images/settings.svg";
import { withTranslation } from "react-i18next";

import {
  REQUEST_POSITIONS,
  REQUEST_NEW_VACANCY,
  REQUEST_CONFIG,
  REQUEST_UPDATE_VACANCY,
} from "../../reducers/vacancy.js";
import { REQUEST_ROLE } from "../../reducers/candidate.js";
import "./index.css";
import { Button } from "react-mdl";
import ReactTooltip from "react-tooltip";
import Handbook from "../../utils/handbook";
const levels = [
  {
    level: 0,
    text: ["C - Level"],
  },
  {
    level: 1,
    text: ["Vice Presidents", "Sr. Vice Presidents"],
  },
  {
    level: 2,
    text: ["Directors", "Sr. Managers"],
  },
  {
    level: 3,
    text: ["Managers"],
  },
  {
    level: 4,
    text: ["Jr. Managers", "Professionals"],
  },
];

function checkCompetency(enabled) {
  return enabled === true || enabled === "true";
}

class NewVacancy extends Component {
  constructor(props) {
    super(props);
    this._close = this._close.bind(this);
    this._nextSection = this._nextSection.bind(this);
    this._previousSection = this._previousSection.bind(this);
    this._toggleModifySkills = this._toggleModifySkills.bind(this);
    this._toggleCompetency = this._toggleCompetency.bind(this);
    this._addMoreSettings = this._addMoreSettings.bind(this);
    this._onCalibrate = this._onCalibrate.bind(this);
    this.renderGeneral = this.renderGeneral.bind(this);
    this.renderSection = this.renderSection.bind(this);
    this.renderSpecify = this.renderSpecify.bind(this);
    this.renderSkills = this.renderSkills.bind(this);
    this.renderSkillsPlus = this.renderSkillsPlus.bind(this);
    this.renderLeftButton = this.renderLeftButton.bind(this);
    this.renderImportant = this.renderImportant.bind(this);
    this.renderCompetencies = this.renderCompetencies.bind(this);
    this.renderWelcome = this.renderWelcome.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.onPositionsRequested = this.onPositionsRequested.bind(this);
    this.onPositionsClear = this.onPositionsClear.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.onChangePosition = this.onChangePosition.bind(this);
    this.selectVacantRole = this.selectVacantRole.bind(this);
    this.selectVacantScope = this.selectVacantScope.bind(this);
    this.selectDisclose = this.selectDisclose.bind(this);
    this.selectConfidential = this.selectConfidential.bind(this);
    this._nextSkills = this._nextSkills.bind(this);
    this._nextWelcome = this._nextWelcome.bind(this);
    this._nextVideo = this._nextVideo.bind(this);
    this._nextWithoutVideo = this._nextWithoutVideo.bind(this);
    this._nextVerify = this._nextVerify.bind(this);
    this._changeCompany = this._changeCompany.bind(this);
    this._nextSpecify = this._nextSpecify.bind(this);
    this.selectEnglish = this.selectEnglish.bind(this);
    this.selectSpanish = this.selectSpanish.bind(this);
    const { t } = props;
    this.sections = [
      { text: t("Let's get started!  It's simple..") },
      { text: t("Specify vacant role here:") },
      {
        supertext: `${t(
          "Herman suggests the following competency set and targets for the position of:"
        )}`,
        text: t("Technology director"),
      },
      { text: t("IMPORTANT") },
      { text: t("Competencies") },
      { text: t("Welcome video") },
    ];
    this.areas = [
      {
        text: t("Sales"),
        icon: salesIcon,
        iconAlt: salesWhiteIcon,
        key: "sales",
      },
      {
        text: t("Operations"),
        icon: salesIcon,
        iconAlt: generalIcon,
        key: "ops",
      },
      {
        text: t("Finance"),
        icon: techIcon,
        iconAlt: techWhiteIcon,
        key: "fin",
      },
      { text: t("IT"), icon: techIcon, iconAlt: techWhiteIcon, key: "tech" },
      { text: t("H.R."), icon: hrIcon, iconAlt: hrWhiteIcon, key: "hr" },
      {
        text: t("Strategy"),
        icon: hrIcon,
        iconAlt: hrWhiteIcon,
        key: "strategy",
      },
      {
        text: t("Legal & Compliance"),
        icon: hrIcon,
        iconAlt: hrWhiteIcon,
        key: "legal",
      },
      {
        text: t("Risk Management"),
        icon: hrIcon,
        iconAlt: hrWhiteIcon,
        key: "risk",
      },
      {
        text: t("Other"),
        icon: developIcon,
        iconAlt: developWhiteIcon,
        key: "other",
      },
    ];
    this.scopes = [
      {
        text: t("Global"),
        icon: techIcon,
        iconAlt: techWhiteIcon,
        key: "global",
      },
      {
        text: t("Regional"),
        icon: salesIcon,
        iconAlt: salesWhiteIcon,
        key: "regional",
      },
      { text: t("Local"), icon: hrIcon, iconAlt: hrWhiteIcon, key: "local" },
    ];
    this.competencies = {
      TL: t("Leadership"),
      AO: t("Results Orientation"),
      ST: t("Strategic Thinking"),
      TC: t("Team Work & Collaboration"),
      DO: t("Developing Others"),
      EI: t("Emotional Intelligence"),
      CO: t("Commercial Drive"),
      CM: t("Change Management"),
    };
    this.state = {
      visible: props.visible || false,
      ceoLevel: 3,
      section: 0,
      modify: false,
      skills: [],
      competencies: [],
      positions: [],
      position: "",
      vacant: Math.floor(this.areas.length / 2),
      scope: Math.floor(this.scopes.length / 2),
      confidential: true,
      changed: false,
      role: null,
      company: "",
      loadedFromServer: false,
      language: localStorage.getItem("language") || "en",
      clearing: !props.vacancy.get("uuid"),
    };
    this.processVisibility(this.state.visible);
    if (props.vacancy.get("uuid")) {
      this.state = Object.assign(
        {},
        this.state,
        this.componentSetFromServer(props)
      );
    }
    this.createTexts(t);
  }

  createTexts(t) {
    const handbook = Handbook.createTexts(t);
    this.skillsHeaders = handbook.skillsHeaders;
    this.skillsTooltip = handbook.skillsTooltip;
  }

  componentDidMount() {
    this.props.vacancyActions.REQUEST_POSITIONS();
    //this._nextSpecify()
  }
  componentWillReceiveProps(newProps) {
    if (!newProps.vacancy.get("uuid")) {
      if (!this.state.clearing) {
        console.log("Clearing data");

        this.setState({
          ceoLevel: 3,
          position: "",
          company: "",
          vacant: Math.floor(this.areas.length / 2),
          scope: Math.floor(this.scopes.length / 2),
          confidential: true,
          modify: false,
          config: undefined,
          loadedFromServer: false,
          clearing: true,
          id: undefined,
        });
      }
    } else {
      if (
        newProps.vacancy.get("uuid") != this.props.vacancy.get("uuid") &&
        newProps.vacancy.get("uuid")
      ) {
        this.setState(this.componentSetFromServer(newProps));
      }
    }
    const { t } = newProps;
    this.sections = [
      { text: t("Let's get started!  It's simple..") },
      { text: t("Specify vacant role here:") },
      {
        supertext: `${t(
          "Herman suggests the following competency set and targets for the position of:"
        )}`,
        text: t("Technology director"),
      },
      { text: t("IMPORTANT") },
      { text: t("Competencies") },
      { text: t("Welcome video") },
    ];
    this.areas = [
      {
        text: t("Sales"),
        icon: salesIcon,
        iconAlt: salesWhiteIcon,
        key: "sales",
      },
      {
        text: t("Operations"),
        icon: salesIcon,
        iconAlt: generalIcon,
        key: "ops",
      },
      {
        text: t("Finance"),
        icon: techIcon,
        iconAlt: techWhiteIcon,
        key: "fin",
      },
      { text: t("IT"), icon: techIcon, iconAlt: techWhiteIcon, key: "tech" },
      { text: t("H.R."), icon: hrIcon, iconAlt: hrWhiteIcon, key: "hr" },
      {
        text: t("Strategy"),
        icon: hrIcon,
        iconAlt: hrWhiteIcon,
        key: "strategy",
      },
      {
        text: t("Legal & Compliance"),
        icon: hrIcon,
        iconAlt: hrWhiteIcon,
        key: "legal",
      },
      {
        text: t("Risk Management"),
        icon: hrIcon,
        iconAlt: hrWhiteIcon,
        key: "risk",
      },
      {
        text: t("Other"),
        icon: developIcon,
        iconAlt: developWhiteIcon,
        key: "other",
      },
    ];
    this.scopes = [
      {
        text: t("Global"),
        icon: techIcon,
        iconAlt: techWhiteIcon,
        key: "global",
      },
      {
        text: t("Regional"),
        icon: salesIcon,
        iconAlt: salesWhiteIcon,
        key: "regional",
      },
      { text: t("Local"), icon: hrIcon, iconAlt: hrWhiteIcon, key: "local" },
    ];
    this.competencies = {
      TL: t("Leadership"),
      AO: t("Results Orientation"),
      ST: t("Strategic Thinking"),
      TC: t("Team Work & Collaboration"),
      DO: t("Developing Others"),
      EI: t("Emotional Intelligence"),
      CO: t("Commercial Drive"),
      CM: t("Change Management"),
    };
    this.createTexts(t);
  }
  componentWillUpdate(nextProps, nextState) {
    this.processVisibility(nextState.visible);
  }
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  componentSetFromServer(props) {
    return {
      ceoLevel: props.vacancy.getIn(["config", "ceo_level"]),
      position: props.vacancy.getIn(["name"]),
      company: props.vacancy.getIn(["company"]),
      vacant: this.areas.findIndex(
        (a) => a.key == props.vacancy.getIn(["config", "area"])
      ),
      scope: this.scopes.findIndex(
        (s) => s.key == props.vacancy.getIn(["config", "scope"])
      ),
      confidential: props.vacancy.getIn(["confidentiality"]),
      modify: true,
      config: Inmutable.Map().set(
        "competencies",
        props.vacancy.getIn(["competencies"])
      ),
      loadedFromServer: true,
      id: props.vacancy.getIn(["uuid"]),
    };
  }

  render() {
    const { visible, section } = this.state;
    const title = this.state.company + " " + this.state.position; //sections[section].text;
    const supertext = this.sections[section].supertext;
    return (
      <div className="background-modal">
        <div className="container newVacancy">
          <div className="" role="document">
            <div className="">
              <div className="">
                <div style={{ width: 100 }}>{this.renderLeftButton()}</div>
                <div>
                  <div
                    className="text text-center"
                    style={{ maxWidth: 400, margin: "0 auto" }}
                  >
                    {supertext}
                  </div>
                  <h5 className="modal-title margin-top-20 text-center">
                    {title}
                  </h5>
                </div>
                <button
                  onClick={this._close}
                  type="button"
                  className="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <img src={closeIcon} />
                  </span>
                </button>
              </div>
              <div className="">{this.renderSection()}</div>
              <div className=" center margin-bottom-20">
                {this.renderButtons()}
              </div>
            </div>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              toastClassName="dark-toast"
              progressClassName="transparent-progress"
              closeOnClick
              pauseOnHover
            />
            <ReactTooltip
              id="new"
              place="right"
              multiline={true}
              isCapture={false}
              getContent={(s) => {
                if (!s) {
                  return null;
                }
                try {
                  const data = JSON.parse(s);
                  return (
                    <div>
                      <div className="tooltipTitle">Level {data.index}</div>
                      {data.elements.map((e) => (
                        <div>- {e}</div>
                      ))}
                    </div>
                  );
                } catch (e) {
                  return null;
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderSuggestion(suggestion) {
    return <span>{suggestion.get("name")}</span>;
  }
  renderSection() {
    const { section } = this.state;
    switch (section) {
      case 0:
        return this.renderGeneral();
      case 1:
        return this.renderSpecify();
      case 2:
        return this.renderSkillsPlus();
      case 3:
        return this.renderImportant();
      case 4:
        return this.renderCompetencies();
      case 5:
        return this.renderWelcome();
      default:
        return null;
    }
  }
  renderButtons() {
    const { t } = this.props;
    const { section } = this.state;
    switch (section) {
      case 0:
        return (
          <HButton
            type="primary"
            className="max-300"
            onClick={this._nextSection}
          >
            {t("Next")}
          </HButton>
        );
      case 1:
        return (
          <HButton
            type="primary"
            className="max-300"
            onClick={this._nextSpecify}
          >
            {t("Next")}
          </HButton>
        );
      case 2:
        return (
          <HButton
            type="primary"
            className="max-300"
            onClick={this._nextVerify}
          >
            {t("Next")}
          </HButton>
        );
      case 3:
        return (
          <div style={{ textAlign: "center" }}>
            <HButton
              type="primary"
              className="max-300"
              onClick={this._previousSection}
            >
              {t("Go back, need to change something")}
            </HButton>
            <HButton
              type="plain"
              className="max-300"
              onClick={this._nextWelcome}
            >
              {t("I am aware, continue with my changes")}
            </HButton>
          </div>
        );
      case 4:
        return (
          <HButton
            type="primary"
            className="max-300"
            onClick={this._nextSkills}
          >
            Next
          </HButton>
        );

      case 5:
        return (
          <div style={{ textAlign: "center" }}>
            <HButton
              type="primary"
              className="max-300"
              onClick={this._nextVideo}
            >
              {t("Record a welcome video")}
            </HButton>
            <HButton
              type="plain"
              className="max-300"
              onClick={this._nextWithoutVideo}
            >
              {t("Record video later")}
            </HButton>
          </div>
        );
    }
  }
  renderLeftButton() {
    const { section, modify } = this.state;
    switch (section) {
      case 2:
        return !modify ? null : (
          <div>
            <HButton type="plain" onClick={this._toggleModifySkills}>
              {this.props.t("Undo")}
            </HButton>
          </div>
        );
      default:
        return null;
    }
  }
  renderGeneral() {
    const { ceoLevel, positions, position, company, language } = this.state;
    const { companies, t } = this.props;
    return (
      <div className="text-center">
        <div>
          <label className="default ">{t("Role of Vacant position")}</label>
        </div>
        <div className="margin-top-20 position">
          <HInput
            className="max-300"
            value={position}
            placeholder={t("Example: Technology Director")}
            onChange={this.onChangePosition}
          />
        </div>
        <div className="margin-top-20">
          <label className="secondary ">
            {t("For")}:{" "}
            <input
              className="inline-input"
              value={company}
              onChange={this._changeCompany}
            />
          </label>
        </div>
        <div class="line-center">
          <img
            src={lineLightGray}
            style={{ width: "100%", height: 2 }}
            className="max-400"
          />
        </div>
        <div className="text-center">
          <label className="default ">{t("Language")}</label>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <HCheckbox checked={language == "en"} onChange={this.selectEnglish}>
            English
          </HCheckbox>
          <HCheckbox checked={language == "es"} onChange={this.selectSpanish}>
            Español
          </HCheckbox>
        </div>
        <div class="line-center">
          <img
            src={lineLightGray}
            style={{ width: "100%", height: 2 }}
            className="max-400"
          />
        </div>
        <div>
          <label className="default ">
            {t("What is the level of the role?")}
          </label>
        </div>
        <div className="margin-top-20">
          <div className="circleOptions numbers">
            <select
              className="hinput max-300 hselect"
              value={ceoLevel}
              onChange={(e) => this._selectCeoLevel(e.target.value)}
            >
              {levels.map((e, i) => (
                <option key={e.level} value={e.level}>
                  {e.text.map((text) => t(text)).join(",")}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
  renderSpecify() {
    const { vacant, scope, confidential } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="text-center">
          <label className="default ">{t("Area")}</label>
        </div>
        <HSelectCircle
          options={this.areas}
          value={vacant}
          onChange={this.selectVacantRole}
          className="area"
        />
        <div class="line-center">
        <img
            src={lineLightGray}
            style={{ width: "100%", height: 2 }}
            className="max-400"
          />
        </div>
        <div className="text-center">
          <label className="default ">{t("Scope")}</label>
        </div>
        <HSelectCircle
          options={this.scopes}
          value={scope}
          onChange={this.selectVacantScope}
        />
        {/*
				<div className="text-center">
					<img src={lineLightGray} style={{width:"100%",height:2}} className="max-400"/>
				</div>
				 
				<div className="text-center">
					<label className="default ">
						{t('Confidentiality')}
					</label>
				</div>
				<div style={{display:"flex",justifyContent:"center"}}>
					<HCheckbox checked={!confidential} onChange={this.selectDisclose}>{t('Disclose')}</HCheckbox>
					<HCheckbox checked={confidential} onChange={this.selectConfidential}>{t('Confidential')}</HCheckbox>
				</div>
				*/}
      </div>
    );
  }

  renderSkillsPlus() {
    const { modify } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div class="line-center">
        <img
            src={lineLightGray}
            style={{ width: "100%", height: 2 }}
            className="max-400"
          />
        </div>
        {!modify ? (
          <div className="text-center">
            <div className="grayBox">
              {t(
                "I make recommendations based on the input of experts and my patented AI / Machine Learning algorithms, take advantage"
              )}
            </div>
          </div>
        ) : (
          <div className="text-center">
            <div className="pinkBox">
              {t(
                "Herman does not encourage changes, define the competencies carefully. Your changes will significantly affect the final results and analysis of candidates"
              )}
            </div>
          </div>
        )}
        <div className="headers">
          {this.skillsHeaders.map((headers, i) => (
            <div className="header skill-level">
              {headers.map((header, i) => (
                <span>
                  {header} {i + 1 !== headers.length ? "/" : ""}
                </span>
              ))}
            </div>
          ))}
        </div>
        <div>
          {!modify
            ? this.props.config
                .get("competencies")
                .filter((v) => checkCompetency(v.get("enabled")))
                .map((v, i) => (
                  <HCalibrateSolid
                    tooltips={this.skillsTooltip[i]}
                    key={i}
                    step={v.get("value")}
                    steps={5}
                    payload={i}
                    onChange={this._onCalibrate}
                    description={this.competencies[i]}
                    progress={((v.get("value") - 1) * 100) / 5}
                    initial="Less"
                    final="More"
                  />
                ))
                .toList()
            : this.state.config
                .get("competencies")
                .filter((v) => checkCompetency(v.get("enabled")))
                .map((v, i) => (
                  <HCalibrateSolid
                    tooltips={this.skillsTooltip[i]}
                    key={i}
                    step={v.get("value")}
                    steps={5}
                    payload={i}
                    onChange={this._onCalibrate}
                    description={this.competencies[i]}
                    progress={((v.get("value") - 1) * 100) / 5}
                    initial="Less"
                    final="More"
                  />
                ))
                .toList()}
        </div>
        <div className="text-center" style={{ marginTop: 20 }}>
          {!modify ? (
            <HButton
              type="only-border"
              className="max-300"
              onClick={this._toggleModifySkills}
            >
              <img src={pencilIcon} />
              <span> {t("Modify")}</span>
            </HButton>
          ) : (
            <HButton
              type="only-border"
              className="max-300"
              onClick={this._addMoreSettings}
            >
              <img src={settingsIcon} />
              <span> {t("Add more competencies")}</span>
            </HButton>
          )}
        </div>
      </div>
    );
  }

  renderSkills() {
    const { modify } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div class="line-center">
        <img
            src={lineLightGray}
            style={{ width: "100%", height: 2 }}
            className="max-400"
          />
        </div>
        {!modify ? (
          <div className="text-center">
            <div className="grayBox">
              {t(
                "I make recommendations based on the input of experts and my patented AI / Machine Learning algorithms, take advantage"
              )}
            </div>
          </div>
        ) : (
          <div className="text-center" style={{marginTop:10}}>
            <div className="pinkBox">
              {t(
                "Herman does not encourage changes, define the competencies carefully. Your changes will significantly affect the final results and analysis of candidates"
              )}
            </div>
          </div>
        )}
        {!modify
          ? this.props.config
              .get("competencies")
              .filter((v) => checkCompetency(v.get("enabled")))
              .map((v, i) => (
                <HCalibrate
                  key={i}
                  steps={6}
                  payload={i}
                  onChange={this._onCalibrate}
                  description={this.competencies[i]}
                  progress={Math.floor((v.get("value") * 100) / 6)}
                  initial="Less"
                  final="More"
                />
              ))
              .toList()
          : this.state.config
              .get("competencies")
              .filter((v) => checkCompetency(v.get("enabled")))
              .map((v, i) => (
                <HCalibrate
                  key={i}
                  steps={6}
                  payload={i}
                  onChange={this._onCalibrate}
                  description={this.competencies[i]}
                  progress={Math.floor((v.get("value") * 100) / 6)}
                  initial="Less"
                  final="More"
                />
              ))
              .toList()}
        <div className="text-center">
          {!modify ? (
            <HButton
              type="only-border"
              className="max-300"
              onClick={this._toggleModifySkills}
            >
              <img src={pencilIcon} />
              <span> {t("Modify")}</span>
            </HButton>
          ) : (
            <HButton
              type="only-border"
              className="max-300"
              onClick={this._addMoreSettings}
            >
              <img src={settingsIcon} />
              <span> {t("Add more competencies")}</span>
            </HButton>
          )}
        </div>
      </div>
    );
  }

  renderImportant() {
    return (
      <div>
        <div className="text-center">
          <div className="max-400 msg">
            {this.props.t(
              "You have made SIGNIFICANT changes to the competency set and its targets. This could result in material deviations in Herman's analysis of your candidates"
            )}
          </div>
        </div>
      </div>
    );
  }

  renderCompetencies() {
    const { config } = this.state;
    return (
      <div>
        <div className="max-400" style={{ margin: "0 auto" }}>
          {config
            .get("competencies")
            .map((e, i) => (
              <HToggle
                key={i}
                label={this.competencies[i]}
                enabled={checkCompetency(e.get("enabled"))}
                payload={i}
                onChange={this._toggleCompetency}
              />
            ))
            .toList()}
        </div>
      </div>
    );
  }

  renderWelcome() {
    const { t } = this.props;
    return (
      <div>
        <div className="text-center">
          <div className="max-400 msg">
            {t(
              "This is a great opportunity to record a welcome video for your candidates and engage them early on"
            )}
          </div>
        </div>
        <div className="text-center margin-top-20">
          <label className="secondary ">{t("Herman suggest to:")}</label>
        </div>
        <div className="margin-top-20">
          <ul
            className="alfa-list text-left max-400"
            style={{ margin: "0 auto" }}
          >
            <li>
              <label className="secondary ">A) </label>{" "}
              {t("Take the time to present yourself as the hiring manager")}
            </li>
            <li>
              <label className="secondary ">B) </label>{" "}
              {t("Provide your company context")}
            </li>
            <li>
              <label className="secondary ">C) </label>{" "}
              {t(
                "Briefly describe the challenges the position will be facing in the short to medium term"
              )}
            </li>
          </ul>
        </div>
      </div>
    );
  }
  selectSpanish() {
    this.setState({
      language: "es",
    });
    this.props.i18n.changeLanguage("es");
  }
  selectEnglish() {
    this.setState({
      language: "en",
    });
    this.props.i18n.changeLanguage("en");
  }
  _changeCompany(ev) {
    this.setState({
      company: ev.target.value,
    });
  }
  onChangePosition(value) {
    this.setState({
      position: value,
    });
  }
  onPositionsRequested() {
    this.setState({
      positions: this.props.positions
        .filter(
          (p) =>
            p
              .get("name")
              .toLowerCase()
              .indexOf(this.state.position.toLowerCase()) >= 0
        )
        .toArray(),
    });
  }

  onPositionsClear() {
    this.setState({
      positions: [],
    });
  }
  getSuggestionValue(suggestion) {
    this.setState({
      //skills: suggestion.get("competencies").toJS(),
      role: suggestion,
    });
    return suggestion.get("name");
  }

  _onCalibrate(value, i) {
    if (this.state.modify) {
      let newSkills = this.state.config;

      this.setState({
        config: newSkills.setIn(["competencies", i, "value"], value),
        changed: true,
      });
    }
  }

  _toggleModifySkills() {
    let modify = !this.state.modify;
    let newState = {
      modify,
    };
    if (modify) {
      newState.config = this.props.config;
      newState.changed = false;
    }
    this.setState(newState);
  }

  _toggleCompetency(value, index) {
    const newCompetencies = this.state.config;
    this.setState({
      config: newCompetencies.setIn(
        ["competencies", index, "enabled"],
        String(value)
      ),
      changed: true,
    });
  }

  _addMoreSettings() {
    this.setState({
      section: 4,
    });
  }

  _nextSection() {
    if (!this.state.position) {
      toast(this.props.t("Please select a role first"));
      return;
    }
    if (!this.state.company) {
      toast(this.props.t("Please select a company first"));
      return;
    }
    this.setState({
      section: this.state.section + 1,
    });
  }
  _previousSection() {
    this.setState({
      section: this.state.section - 1,
    });
  }
  _selectCeoLevel(e) {
    this.setState({
      ceoLevel: e,
    });
  }

  _close() {
    this.props.actions.push("/home");
  }

  processVisibility(visible) {
    if (visible) {
      document.getElementById("backdrop").className = "modal-backdrop show";
      document.getElementById("main").classList.add("modal-open");
    } else {
      document.getElementById("backdrop").className = "";
      document.getElementById("main").classList.remove("modal-open");
    }
  }
  selectVacantRole(value) {
    this.setState({
      vacant: value,
    });
  }
  selectVacantScope(value) {
    this.setState({
      scope: value,
    });
  }
  selectDisclose() {
    this.setState({
      confidential: false,
    });
  }
  selectConfidential() {
    this.setState({
      confidential: true,
    });
  }
  _nextSkills() {
    this.setState({
      section: 2,
    });
  }
  _nextWelcome() {
    this.setState({
      section: 5,
    });
  }
  _nextSpecify() {
    this.props.vacancyActions.REQUEST_CONFIG({
      ceo_level: this.state.ceoLevel,
      scope: this.scopes[this.state.scope].key,
      area: this.areas[this.state.vacant].key,
    });
    this.setState({
      section: 2,
    });
  }
  _nextWithoutVideo() {
    this.createVacancy().then((uuid) => {
      if (uuid) {
        this.props.actions.push(`/email/${uuid}`);
      } else {
        toast(this.props.t("There was an error creating the position"));
      }
    });
  }
  _nextVideo(uuid) {
    this.createVacancy().then((uuid) => {
      if (uuid) {
        this.props.push(`/record/${uuid}`);
      } else {
        toast(this.props.t("There was an error creating the position"));
      }
    });
  }
  createVacancy() {
    return new Promise((resolve) => {
      const request = {
        vacancy: {
          name: this.state.position,
          config: this.props.config.get("uuid"),
          company: this.state.company,
          language: this.state.language,
          confidentiality: this.state.confidential,
          competencies: !this.state.modify
            ? this.props.config.get("competencies").toJS()
            : this.state.config.get("competencies").toJS(),
        },
        onComplete: ({ success, uuid }) => {
          if (success) {
            return resolve(uuid);
          }
          resolve(null);
        },
      };
      if (this.state.loadedFromServer) {
        request.uuid = this.props.vacancy.get("uuid");
        this.props.vacancyActions.REQUEST_UPDATE_VACANCY(request);
      } else {
        this.props.vacancyActions.REQUEST_NEW_VACANCY(request);
      }
    });
  }
  _nextVerify() {
    if (this.state.changed) {
      this.setState({
        section: 3,
      });
    } else {
      this.setState({
        section: 5,
      });
    }
  }
  clear() {
    this.setState({
      ceoLevel: 3,
      section: 0,
      modify: false,
      skills: [],
      competencies: [],
      positions: [],
      position: "",
      role: null,
      vacant: Math.floor(this.areas.length / 2),
      scope: Math.floor(this.scopes.length / 2),
      confidential: true,
      changed: false,
    });
  }
}

function mapStateToProps(state) {
  return {
    positions: state.vacancy.get("positions"),
    companies: state.vacancy.get("companies"),
    config: state.vacancy.get("config"),
    vacancy: state.vacancy.get("vacancy"),
    role: state.candidate.get("role"),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ push }, dispatch),
    vacancyActions: bindActionCreators(
      {
        REQUEST_POSITIONS,
        REQUEST_NEW_VACANCY,
        REQUEST_CONFIG,
        REQUEST_UPDATE_VACANCY,
      },
      dispatch
    ),
    candidateActions: bindActionCreators({ REQUEST_ROLE }, dispatch),
  };
}
function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withTranslation()(NewVacancy));
