import request from "superagent";
import { getAuth } from "firebase/auth";
import {firebaseApp} from "../utils/firebase.js"

const getToken=  async() =>{
  await getAuth(firebaseApp).authStateReady();
  if(getAuth(firebaseApp).currentUser == null){
    const err= new Error("Not authenticated");
    err.status = 403
    throw err;
  }
  return getAuth(firebaseApp).currentUser.getIdToken();
}

export default {
  
  requestProfile: async () =>{
    const idToken = await getToken()
    console.log(idToken)
    return request
      .get(window.API_BASE + "/api/assessment/profile/")
      .set("Authorization", "Bearer "+ idToken)
  },
  requestInterviews: async(vacancy) => {
    const idToken = await getToken()

    return request
      .get(
        window.API_BASE +
          "/api/assessment/vacancy/" +
          vacancy +
          "/interview/all/"
      )
      .set("Authorization", "Bearer "+ idToken)
  },
  requestInterview: async (interview) =>{
    const idToken = await getToken()
    return request
      .get(window.API_BASE + "/api/assessment/interview/" + interview + "/")
      .set("Authorization", "Bearer "+ idToken)
  },
  requestGetShareURL: async(id) => {
    const idToken = await getToken()
    return request
      .get(window.API_BASE + "/api/assessment/vacancy/" + id + "/share_url/")
      .set("Authorization", "Bearer "+ idToken)
  }
};
