import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HImageUpload from "../../components/himageUpload";
import HInput from "../../components/hinput";
import camSquare from "../../images/eComposer/camSquare.svg";
import timeDeadline from "../../images/eComposer/timeDeadline.svg";
import faqDeadline from "../../images/eComposer/faqDeadline.svg";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import { isValidEmail } from "../../utils";
import { formatDate, parseDate } from "react-day-picker/moment";
import { withTranslation } from "react-i18next";

import "react-day-picker/lib/style.css";
import "./index.css";
import {
  REQUEST_UPDATE_LOGO,
  REQUEST_UPDATE_VACANCY,
} from "../../reducers/vacancy";
import { ToastContainer, toast } from "react-toastify";

class EmailComposer extends Component {
  constructor(props) {
    super(props);

    this.changeEmail = this.changeEmail.bind(this);
    this.changeSubject = this.changeSubject.bind(this);
    this._saveEmail = this._saveEmail.bind(this);
    this.changeKeyQuestion = this.changeKeyQuestion.bind(this);
    this.changeEmailRejection = this.changeEmailRejection.bind(this);
    this.changeSubjectRejection = this.changeSubjectRejection.bind(this);
    this.changeEmailAcceptance = this.changeEmailAcceptance.bind(this);
    this.changeSubjectAceptance = this.changeSubjectAceptance.bind(this);

    const { t } = this.props;
    this.placeholderEmail =
      `${t(
        "Congratulations! You have been selected to participate in the interviewing process for the {position} position at {company}"
      )}.` +
      `${t(
        "We are very excited to introduce you to a new digital interviewing experience that will allow us to get to know you much better and ensure you will be where you are the best fit to thrive your career"
      )}. ` +
      `${t("We also want to help you save time during this process")}.\n\n` +
      `${t(
        "We hope you enjoy this experience and the feedback report that is waiting for you, immediately, at the end of the interviewing process"
      )}.` +
      `${t(
        "Regardless of the final outcome, we hope this state of the art digital experience will also give you the opportunity to learn more about yourself"
      )}.\n\n` +
      `${t(
        "You will see more details about how to log in at the bottom of this email. With my warmest regards"
      )}.`;
    this.placeholderDline = t("Please set visible deadline for candidates");

    this.placeholderSubject = t("Subject of e-mail");

    this.placeholderSubjectRejection = t("Subject of rejection e-mail");

    this.placeholderKeyQuestion = t("Key question");

    this.state = {
      value: t("Please set visible deadline for candidates"),
      email: this.placeholderEmail,
      line: this.placeholderDline,
      subject: t("Invite"),
      subjectRejection: "-",
      emailRejection: "-",
      subjectAcceptance: "-",
      emailAcceptance: "-",
      selectedDay: undefined,
      image: null,
      keyQuestion: "",
      emailReporter: "",
      errors: {
        emailReporter: false,
      },
    };
    if (props.vacancy.get("uuid")) {
      this.state = Object.assign(
        {},
        this.state,
        this.componentSetFromServer(props)
      );
    }
  }
  componentWillReceiveProps(newProps) {
    console.log("receving props..");

    const emailReporter = newProps.vacancy.get("email_reporter");
    this.setState({ emailReporter });

    const { t } = newProps;
    this.placeholderEmail =
      `${t(
        "Congratulations! You have been selected to participate in the interviewing process for the {position} position at {company}"
      )}.` +
      `${t(
        "We are very excited to introduce you to a new digital interviewing experience that will allow us to get to know you much better and ensure you will be where you are the best fit to thrive your career"
      )}. ` +
      `${t("We also want to help you save time during this process")}.\n\n` +
      `${t(
        "We hope you enjoy this experience and the feedback report that is waiting for you, immediately, at the end of the interviewing process"
      )}.` +
      `${t(
        "Regardless of the final outcome, we hope this state of the art digital experience will also give you the opportunity to learn more about yourself"
      )}.\n\n` +
      `${t(
        "You will see more details about how to log in at the bottom of this email. With my warmest regards"
      )}.`;
    if (newProps.vacancy.get("email")) {
      this.setState({
        email: newProps.vacancy.get("email") || this.placeholderEmail,
      });
    } else {
      this.setState({ email: this.placeholderEmail });
    }
    this.placeholderDline = t("Please set visible deadline for candidates");

    this.placeholderSubject = t("Subject of e-mail");

    //this.placeholderSubjectRejection = t('Subject of rejection e-mail')

    this.placeholderKeyQuestion = t("Key question");
    if (
      newProps.vacancy.get("uuid") != this.props.vacancy.get("uuid") &&
      newProps.vacancy.get("uuid")
    ) {
      this.setState(this.componentSetFromServer(newProps));
    }
  }
  componentSetFromServer(props) {
    let serverState = {
      selectedDay: moment(props.vacancy.get("end_date")).toDate(),
      image: props.vacancy.get("image_url"),
    };

    if (props.vacancy.get("subject_email")) {
      serverState["subject"] = props.vacancy.get("subject_email");
    } else {
      serverState["subject"] = props.t("Invite");
    }
    /*
		if (props.vacancy.get("rejection_email")) {
			serverState["emailRejection"] = props.vacancy.get("rejection_email")
		}else{
			serverState["emailRejection"] = ""
		}
		if (props.vacancy.get("subject_rejection_email")) {
			serverState["subjectRejection"] = props.vacancy.get("subject_rejection_email")
		}else{
			serverState["subjectRejection"] = ""
		}

		if (props.vacancy.get("acceptance_email")) {
			serverState["emailAcceptance"] = props.vacancy.get("acceptance_email")
		}else{
			serverState["emailAcceptance"] = ""
		}
		if (props.vacancy.get("subject_acceptance_email")) {
			serverState["subjectAcceptance"] = props.vacancy.get("subject_acceptance_email")
		}else{
			serverState["subjectAcceptance"] = ""
		}
*/
    if (props.vacancy.getIn(["key_questions", "questions", "0", "text"])) {
      serverState["keyQuestion"] = props.vacancy.getIn([
        "key_questions",
        "questions",
        "0",
        "text",
      ]);
    } else {
      serverState["keyQuestion"] = "";
    }
    return serverState;
  }

  handleDayChange = (selectedDay) => {
    this.setState({ selectedDay });
  };

  render() {
    const { t } = this.props;
    const value = this.state.selectedDay;
    const { email, subject, image, keyQuestion } = this.state;

    return (
      <div className="eComposer-container">
        <div className="cAvailable">
          {t("Candidates available")}:{" "}
          <span className="textAvailable">1 de 5</span>
        </div>
        <div className="eContainer">
          <div className="textContainer">
            {t(
              "Create a standard e-mail message that will be sent to your candidates to start the interviewing process with Herman"
            )}
            .{t("Personalization of the message is highly encouraged!")}
          </div>
          <div className="eContainer-title">
            {`${this.props.vacancy.get("company")} ${this.props.vacancy.get(
              "name"
            )}`}
          </div>

          <div className="box2">
            <HImageUpload ref="imageUpload" image={image} />
            <img id="camSquare" className="camS" src={camSquare} />
            <div className="textCam">{t("Company Image (Optional)")}</div>
          </div>
          <div className="fromEmail">
            <HInput
              name="keyQuestion"
              placeholder={this.placeholderKeyQuestion}
              value={keyQuestion}
              className="textSubject"
              onChange={this.changeKeyQuestion}
            />
            <div
              className="textContainer"
              style={{ padding: 10, marginBottom: 20 }}
            >
              {t("Email Address to receive reports weekly")}
            </div>
            {this.state.errors.emailReporter && (
              <div
                className="textContainer"
                style={{ padding: 10, marginBottom: 5, color: "red" }}
              >
                {t("This should contains a valid email address")}
              </div>
            )}
            {
              //TODO: Refactor HInput in favor it could handle errors by itself (maybe use react-forms)
            }
            <HInput
              name="emailReporter"
              placeholder={t("Email Address")}
              className="textSubject"
              onChange={(value) => {
                this.updateStateByKey(value, "emailReporter");
                this.validateEmail(value);
              }}
              value={this.state.emailReporter}
              style={{
                borderColor: this.state.errors.emailReporter
                  ? "red"
                  : "inherit",
              }}
              type="email"
              maxlength="100"
            />
            <div className="textContainer">{t("Invite Email")}</div>
            <HInput
              name="subjectEmail"
              placeholder={this.placeholderSubject}
              value={subject}
              className="textSubject"
              onChange={this.changeSubject}
              maxlength="100"
            />
            <textarea
              name="textEmail"
              rows="16"
              className="txtEmail border border-gray-600"
              value={email}
              onChange={this.changeEmail}
            ></textarea>
            {/* 
							<div className="textContainer">
								{t('Rejection Email')}
							</div>
							<HInput name="subjectEmailRejection" placeholder={this.placeholderSubjectRejection} value={subjectRejection} className="textSubject" onChange={this.changeSubjectRejection}/>
							<textarea name="textEmailRejection" rows="16" className="txtEmail" value={emailRejection} onChange={this.changeEmailRejection}>
							</textarea>
							<div className="textContainer">
								{t('Acceptance Email')}
							</div>
							<HInput name="subjectEmailAcceptance" placeholder={placeholderSubjectAcceptance} value={subjectAcceptance} className="textSubject" onChange={this.changeSubjectAceptance}/>
							<textarea name="textEmailAcceptance" rows="16" className="txtEmail" value={emailAcceptance} onChange={this.changeEmailAcceptance}>
							</textarea>
							*/}
            <div className="input-group txtDeadline">
              <span>
                <img src={timeDeadline} />
              </span>
              <form>
                <DayPickerInput
                  inputProps={{ className: "containerDay" }}
                  placeholder={t("Please set visible deadline for candidates")}
                  format="DD/MM/YYYY"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  value={value}
                  dayPickerProps={{
                    modifiers: {
                      disabled: {
                        before: new Date(),
                      },
                    },
                  }}
                  onDayChange={this.handleDayChange}
                />
              </form>
              <span className="faqDay">
                <img src={faqDeadline} />
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <button
            type="button"
            className="btn btn-primary bg-blue-500 reviewButton"
            onClick={this._saveEmail}
          >
            {t("Save and continue")}
          </button>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          toastClassName="dark-toast"
          progressClassName="transparent-progress"
          closeOnClick
          pauseOnHover
        />
      </div>
    );
  }
  validateEmail = (event) => {
    const value = event;

    this.setState({ errors: { ["emailReporter"]: false } });

    if (value) {
      const invalidEmail = !isValidEmail(value);

      this.setState({ errors: { ["emailReporter"]: invalidEmail } });
    }

    return;
  };
  updateStateByKey = (value, key) => {
    this.setState({ [key]: value });
  };
  // TODO: Refactor this and change to use updateStateByKey method.
  // Remove ALL METHODS in favor of updateStateByKey
  changeEmail(ev) {
    this.setState({
      email: ev.target.value,
    });
  }
  changeSubject(value) {
    this.setState({
      subject: value,
    });
  }
  changeEmailRejection(ev) {
    this.setState({
      emailRejection: ev.target.value,
    });
  }
  changeSubjectRejection(value) {
    this.setState({
      subjectRejection: value,
    });
  }
  changeEmailAcceptance(ev) {
    this.setState({
      emailAcceptance: ev.target.value,
    });
  }
  changeSubjectAceptance(value) {
    this.setState({
      subjectAcceptance: value,
    });
  }
  changeKeyQuestion(value) {
    this.setState({
      keyQuestion: value,
    });
  }
  _saveEmail() {
    const promises = [];
    promises.push(
      new Promise((resolve) => {
        if (this.refs.imageUpload.selectedFile) {
          this.props.vacancyActions.REQUEST_UPDATE_LOGO({
            uuid: this.props.vacancy.get("uuid"),
            image: this.refs.imageUpload.selectedFile,
            onComplete: ({ success }) => {
              resolve(success);
            },
          });
        } else {
          resolve(true);
        }
      })
    );

    promises.push(
      new Promise((resolve) => {
        this.props.vacancyActions.REQUEST_UPDATE_VACANCY({
          uuid: this.props.vacancy.get("uuid"),
          vacancy: {
            subject_email: this.state.subject,
            email: this.state.email,
            email_reporter: this.state.emailReporter || null,
            rejection_email: this.state.emailRejection,
            subject_rejection_email: this.state.subjectRejection,
            acceptance_email: this.state.emailAcceptance,
            subject_acceptance_email: this.state.subjectAcceptance,
            end_date: moment(this.state.selectedDay).format("YYYY-MM-DD"),
            key_questions: {
              questions: [{ text: this.state.keyQuestion }],
            },
          },
          onComplete: ({ success }) => {
            resolve(success);
          },
        });
      })
    );
    Promise.all(promises).then(([successUpload, successPath]) => {
      if (successUpload && successPath) {
        this.props.push("/invite");
      } else {
        toast(this.props.t("Please fill all the fields"));
      }
    });
  }
}

function mapStateToProps(state) {
  return {
    vacancy: state.vacancy.get("vacancy"),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    vacancyActions: bindActionCreators(
      { REQUEST_UPDATE_LOGO, REQUEST_UPDATE_VACANCY },
      dispatch
    ),
  };
}
function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withTranslation()(EmailComposer));
